import React, { useState } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import './SimplifiedAssessment.css';
import { Link } from 'react-router-dom';


const SimplifiedAssessment = ({ userId, onAssessmentComplete }) => {
  // Base states from original implementation
  const [step, setStep] = useState('category');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [customGoal, setCustomGoal] = useState('');
  const [weeklyPrompts, setWeeklyPrompts] = useState(['', '', '', '']);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);


  // New states for schedule configuration
  const [scheduleConfig, setScheduleConfig] = useState({
    durationType: 'weekly',
    numberOfPeriods: 4,
    scheduleType: 'weekly',
    scheduleDays: [],
    scheduleWeek: 0,
    startDate: new Date().toISOString().split('T')[0]
  });

  const categories = [
    { value: 'Work Activities', label: 'Work Activities' },
    { value: 'Personal Matters', label: 'Personal Matters' },
    { value: 'Sacramental Life', label: 'Sacramental Life' },
    { value: 'Prayer and Scripture', label: 'Prayer and Scripture' },
    { value: 'Community and Evangelization', label: 'Community and Evangelization' },
  ];

  const weekdays = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ];

  const weekOptions = ['First', 'Second', 'Third', 'Fourth', 'Last'];


  const getCategoryIcon = (category) => {
    const icons = {
      'Work Activities': '💼',
      'Personal Matters': '❤️',
      'Sacramental Life': '🕊️',
      'Prayer and Scripture': '📖',
      'Community and Evangelization': '🤝'
    };
    return icons[category] || '✨';
  };

  const getCategoryDescription = (category) => {
    const descriptions = {
      'Work Activities': 'Integrate faith into your professional life',
      'Personal Matters': 'Strengthen your personal spiritual journey',
      'Sacramental Life': 'Deepen your connection with the sacraments',
      'Prayer and Scripture': 'Develop your prayer life and scripture study',
      'Community and Evangelization': 'Grow in community and share your faith'
    };
    return descriptions[category] || 'Create a custom spiritual goal';
  };


  const getScheduleDescription = (config) => {
    if (config.durationType === 'weekly') {
      if (config.scheduleType === 'specific_weekday') {
        return 'Fixed days each week for consistent routine';
      }
      return 'Flexible weekly check-ins to maintain steady progress';
    }

    if (config.durationType === 'monthly') {
      if (config.scheduleType === 'specific_weekday') {
        return 'Set weekdays each month for structured practice';
      }
      if (config.scheduleType === 'specific_date') {
        return 'Fixed dates monthly for regular commitment';
      }
      return 'Monthly focus with flexible check-ins';
    }

    return 'Customizable schedule to fit your needs';
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (e) {
      return 'Invalid Date';
    }
  };












  const categoryGoals = {
    'Sacramental Life': [
      {
        id: 1, 
        level: "Seeker",
        description: "Do one Catholic activity each week",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly, any day',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly focus',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Light a candle at a church or cemetery",
            "Watch a YouTube video on Fr. Mike Schmitz (search for Eucharist, Mass, or Confession)",
            "Visit a Catholic church and spend 10 minutes in quiet reflection",
            "Listen to a Catholic podcast episode and reflect on it using CrossValidation.ai"
          ],
          monthly: [
            "Complete at least 3 Catholic activities this month (candle lighting, church visits, videos)",
            "Develop a routine of engaging with Catholic content weekly",
            "Establish regular quiet reflection times in Catholic spaces",
            "Create a sustainable schedule for Catholic devotional activities"
          ]
        }
      },
      {
        id: 2,
        level: "Seeker",
        description: "Attend Sunday Mass",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'specific_weekday',
          scheduleDays: [0],
        },
        scheduleOptions: [
          {
            label: 'Weekly Mass (Sundays)',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'specific_weekday',
              scheduleDays: [0]
            }
          },
          {
            label: 'Monthly Mass reflection',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Read and reflect on the Sunday Gospel before Mass",
            "Attend Mass one Sunday",
            "Talk to someone within the parish community after Mass",
            "Write down one key message from the homily"
          ],
          monthly: [
            "Establish a routine of Sunday Mass attendance and reflection",
            "Build relationships within your parish community",
            "Review and synthesize the key themes from this month's homilies",
            "Plan ways to integrate Mass messages into daily life"
          ]
        }
      },
      {
        id: 3,
        level: "Seeker",
        description: "Learn about the sacraments of Eucharist, Confession, and Vocation",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly study',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly deep dive',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Research the importance of these sacraments in the Church",
            "Reflect on their impact on the faith journey",
            "Watch a video explaining one of these sacraments",
            "Discuss a sacrament with a practicing Catholic friend or family member"
          ],
          monthly: [
            "Study one sacrament in depth (Eucharist, Confession, or Vocation)",
            "Interview Catholics about their experience with these sacraments",
            "Create a personal plan for engaging with these sacraments",
            "Summarize and share your learnings about the sacraments"
          ]
        }
      },
      {
        id: 4,
        level: "Disciple",
        description: "Attend regular Mass",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'specific_weekday',
          scheduleDays: [0], // Sunday
        },
        scheduleOptions: [
          {
            label: 'Weekly Mass attendance',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'specific_weekday',
              scheduleDays: [0]
            }
          },
          {
            label: 'Monthly Mass ministry',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Reflect on the Sunday Gospel and homily outside of Mass",
            "Attend a weekday Mass in addition to Sunday",
            "Review some daily Mass readings and reflect via CrossValidation.ai",
            "Invite a friend or family member to join you for Mass"
          ],
          monthly: [
            "Establish a regular pattern of Mass attendance",
            "Explore different Mass times and parishes",
            "Deepen your participation in the Mass community",
            "Share your Mass experiences with others"
          ]
        }
      },
      {
        id: 5,
        level: "Disciple",
        description: "Create a Confession schedule",
        defaultSchedule: {
          durationType: 'monthly',
          numberOfPeriods: 3,
          scheduleType: 'monthly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Monthly Confession',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          },
          {
            label: 'Weekly preparation',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          monthly: [
            "Establish a monthly Confession routine",
            "Develop a thorough examination of conscience practice",
            "Experience the sacrament and reflect on its graces",
            "Share the benefits of regular Confession with others"
          ],
          weekly: [
            "Pray an Act of Contrition daily",
            "Prepare for Confession through examination of conscience",
            "Attend Confession and reflect on the experience",
            "Maintain a spiritual journal about growth through Confession"
          ]
        }
      },

      {
        id: 6,
        level: "Disciple",
        description: "Spend time in Adoration",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly Adoration',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly holy hour',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Spend 30 minutes in Adoration",
            "Reflect on thoughts and intentions considered during Adoration",
            "Discuss your Adoration experience with a spiritual companion or CrossValidation.ai",
            "Invite someone to join you for Adoration"
          ],
          monthly: [
            "Establish a regular Adoration practice (aim for weekly visits)",
            "Develop different prayer methods for Adoration time",
            "Create an Adoration journal and review your experiences",
            "Share the impact of Adoration on your spiritual life"
          ]
        }
      },
      {
        id: 7,
        level: "Apostle",
        description: "Deepen your Mass participation",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'specific_weekday',
          scheduleDays: [0], // Sunday
        },
        scheduleOptions: [
          {
            label: 'Weekly Mass ministry',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'specific_weekday',
              scheduleDays: [0]
            }
          },
          {
            label: 'Monthly Mass leadership',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Attend two weekday Masses",
            "Read and meditate on the Mass readings before attending",
            "Volunteer as a lector or eucharistic minister",
            "Lead a discussion group on the Sunday readings"
          ],
          monthly: [
            "Train for a new Mass ministry role",
            "Coordinate with other Mass ministers and volunteers",
            "Develop resources for Mass participation",
            "Mentor others in Mass ministries"
          ]
        }
      },
      {
        id: 8,
        level: "Apostle",
        description: "Commit to regular Adoration",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly Adoration leadership',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly Adoration ministry',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Spend an hour in Adoration twice this week",
            "Keep an Adoration journal and review it periodically",
            "Organize a group Adoration hour",
            "Learn about and try different prayer methods during Adoration"
          ],
          monthly: [
            "Establish a regular weekly Adoration schedule",
            "Organize and lead Adoration events",
            "Develop resources for Adoration practices",
            "Mentor others in Adoration devotion"
          ]
        }
      },
      {
        id: 9,
        level: "Apostle",
        description: "Become a sacramental mentor",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly mentoring',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly mentorship program',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Offer to sponsor an RCIA candidate",
            "Help prepare children for First Communion or Confirmation",
            "Share your sacramental experiences in a parish talk",
            "Create a sacramental preparation resource with help from CrossValidation.ai"
          ],
          monthly: [
            "Develop a comprehensive mentorship program",
            "Create resources for sacramental preparation",
            "Lead sacramental preparation sessions",
            "Evaluate and improve mentorship approaches"
          ]
        }
      }
    ],

    'Prayer and Scripture': [
      {
        id: 10,
        level: "Seeker",
        description: "Establish a daily prayer habit",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'daily',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly prayer development',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'daily',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly prayer journey',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Set aside 5 minutes each day for prayer",
            "Try different prayer methods (vocal, mental, contemplative)",
            "Use a guided prayer resource or app",
            "Write a reflection on your experience in prayer"
          ],
          monthly: [
            "Establish a consistent daily prayer routine",
            "Explore and practice various prayer forms",
            "Create a prayer journal and track your growth",
            "Share your prayer journey with others"
          ]
        }
      },


      {
        id: 11,
        level: "Seeker",
        description: "Engage with Scripture daily",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'daily',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly Scripture study',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'daily',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly Scripture journey',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Read a short Bible verse each day",
            "Use a Bible app or website for daily readings",
            "Learn about Lectio Divina and try it with a scripture passage",
            "Discuss a Bible passage with a friend or using CrossValidation.ai"
          ],
          monthly: [
            "Establish a daily Scripture reading routine",
            "Study one book of the Bible in depth",
            "Practice different methods of Scripture study",
            "Create a Scripture study journal"
          ]
        }
      },
      {
        id: 12,
        level: "Seeker",
        description: "Learn about the Mysteries of the Rosary",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly Rosary practice',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly Rosary devotion',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Pray a decade of the Rosary",
            "Read about the Mysteries of the Rosary",
            "Pray a full Rosary",
            "Reflect on a Mystery using sacred art"
          ],
          monthly: [
            "Master all four sets of Mysteries",
            "Develop a regular Rosary routine",
            "Create a Rosary meditation journal",
            "Lead others in praying the Rosary"
          ]
        }
      },
      {
        id: 13,
        level: "Disciple",
        description: "Deepen your prayer life",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'daily',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly prayer development',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'daily',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly prayer mastery',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Extend daily prayer time to 15 minutes",
            "Learn and practice the Examen prayer",
            "Try praying with Scripture using Lectio Divina",
            "Share your prayer experiences with a spiritual companion or CrossValidation.ai"
          ],
          monthly: [
            "Establish an extended daily prayer routine",
            "Master various forms of prayer",
            "Create a comprehensive prayer journal",
            "Guide others in deepening their prayer life"
          ]
        }
      },
      {
        id: 14,
        level: "Disciple",
        description: "Study the Sunday readings",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'specific_weekday',
          scheduleDays: [0], // Sunday
        },
        scheduleOptions: [
          {
            label: 'Weekly Scripture study',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'specific_weekday',
              scheduleDays: [0]
            }
          },
          {
            label: 'Monthly Scripture depth',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Read the upcoming Sunday readings early in the week",
            "Use a Catholic commentary to deepen understanding",
            "Discuss the readings with a friend or family member",
            "Reflect on how the readings apply to your life"
          ],
          monthly: [
            "Create a Sunday readings study routine",
            "Research historical and cultural context",
            "Lead Scripture discussions",
            "Write reflections on monthly Scripture themes"
          ]
        }
      },
      {
        id: 15,
        level: "Disciple",
        description: "Explore Catholic devotions",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly devotional practice',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly devotional journey',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Practice a different Catholic devotion each week",
            "Learn about traditional Catholic prayers",
            "Participate in a novena or other devotional practice",
            "Share your experiences with others"
          ],
          monthly: [
            "Master one type of Catholic devotion",
            "Create a personal devotional routine",
            "Lead others in devotional practices",
            "Document your spiritual growth"
          ]
        }
      }
    ],  // Close Prayer and Scripture section
    
    'Work Activities': [
      {
        id: 16,
        level: "Seeker",
        description: "Integrate faith into your workday",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly integration',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly workplace ministry',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Begin each workday with a short prayer",
            "Practice one act of workplace charity",
            "Reflect on how your work serves others",
            "Share a faith insight with a colleague"
          ],
          monthly: [
            "Develop a faith-based work routine",
            "Create opportunities for workplace ministry",
            "Build relationships with faith-minded colleagues",
            "Plan faith integration strategies"
          ]
        }
      },
      {
        id: 17,
        level: "Seeker",
        description: "Practice workplace ethics",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly reflection',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly deep dive',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Review Catholic social teaching principles",
            "Apply ethical principles to a work situation",
            "Reflect on workplace relationships",
            "Consider ways to promote justice at work"
          ],
          monthly: [
            "Study Catholic business ethics",
            "Develop ethical decision frameworks",
            "Create workplace justice initiatives",
            "Lead ethical discussions"
          ]
        }
      },
      {
        id: 18,
        level: "Disciple",
        description: "Lead by Catholic example",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly leadership',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly development',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Model Catholic values in decisions",
            "Mentor others in ethical leadership",
            "Create positive workplace culture",
            "Share faith-based leadership insights"
          ],
          monthly: [
            "Develop Catholic leadership principles",
            "Build faith-based mentoring programs",
            "Lead workplace spirituality groups",
            "Create ethical business practices"
          ]
        }
      }
    ],

    'Personal Matters': [
      {
        id: 19,
        level: "Seeker",
        description: "Develop Catholic family traditions",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'specific_weekday',
          scheduleDays: [0], // Sunday
        },
        scheduleOptions: [
          {
            label: 'Weekly family time',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'specific_weekday',
              scheduleDays: [0]
            }
          },
          {
            label: 'Monthly family ministry',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Pray together as a family",
            "Share faith stories at dinner",
            "Participate in family devotions",
            "Create Catholic home traditions"
          ],
          monthly: [
            "Establish regular family faith practices",
            "Plan special religious celebrations",
            "Develop family prayer routines",
            "Create family faith resources"
          ]
        }
      },
      {
        id: 20,
        level: "Disciple",
        description: "Catholic parenting practices",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly parenting focus',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly development',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Teach children about saints",
            "Practice family prayer time",
            "Discuss Sunday's Gospel",
            "Share Catholic values in daily life"
          ],
          monthly: [
            "Create family faith education plan",
            "Develop Catholic parenting strategies",
            "Plan family spiritual activities",
            "Build Catholic family community"
          ]
        }
      }
    ],

    'Community and Evangelization': [
      {
        id: 21,
        level: "Seeker",
        description: "Join a parish ministry",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'specific_weekday',
          scheduleDays: [0],
        },
        scheduleOptions: [
          {
            label: 'Weekly ministry',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'specific_weekday',
              scheduleDays: [0]
            }
          },
          {
            label: 'Monthly service',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Attend ministry orientation",
            "Participate in ministry activity",
            "Connect with ministry team",
            "Reflect on service experience"
          ],
          monthly: [
            "Explore different ministries",
            "Develop ministry skills",
            "Build ministry relationships",
            "Plan future involvement"
          ]
        }
      },
      {
        id: 22,
        level: "Disciple",
        description: "Lead community outreach",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly leadership',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly planning',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Organize outreach activity",
            "Coordinate volunteer team",
            "Engage with community needs",
            "Document impact and progress"
          ],
          monthly: [
            "Develop outreach strategy",
            "Build community partnerships",
            "Train volunteer leaders",
            "Evaluate program effectiveness"
          ]
        }
      },
      {
        id: 23,
        level: "Apostle",
        description: "Start evangelization initiatives",
        defaultSchedule: {
          durationType: 'weekly',
          numberOfPeriods: 4,
          scheduleType: 'weekly',
          scheduleDays: [],
        },
        scheduleOptions: [
          {
            label: 'Weekly evangelization',
            config: {
              durationType: 'weekly',
              numberOfPeriods: 4,
              scheduleType: 'weekly',
              scheduleDays: []
            }
          },
          {
            label: 'Monthly program',
            config: {
              durationType: 'monthly',
              numberOfPeriods: 3,
              scheduleType: 'monthly',
              scheduleDays: []
            }
          }
        ],
        prompts: {
          weekly: [
            "Plan evangelization event",
            "Create faith sharing materials",
            "Train evangelization team",
            "Document conversion stories"
          ],
          monthly: [
            "Develop evangelization strategy",
            "Build evangelization resources",
            "Launch new initiatives",
            "Evaluate program impact"
          ]
        }
      }
    ]
  };

  const handleCategorySelect = (category) => {
    if (userId > 4000) {
      setShowRegistrationModal(true);
    } else {
      setSelectedCategory(category);
      setStep(category === 'custom' ? 'customGoal' : 'goal');
    }
  };

  const handleGoalSelect = (goal) => {
    setSelectedGoal(goal);
    
    // Initialize with default schedule but allow for customization
    const initialSchedule = {
      ...goal.defaultSchedule,
      numberOfPeriods: goal.defaultSchedule.numberOfPeriods || 4, // Default to 4 if not specified
      scheduleType: goal.defaultSchedule.scheduleType || 'weekly',
      scheduleDays: goal.defaultSchedule.scheduleDays || []
    };
    
    setScheduleConfig(initialSchedule);
    
    // Initialize prompts with defaults but allow for expansion
    const initialPrompts = goal.prompts ? 
      goal.prompts[initialSchedule.durationType] : 
      Array(initialSchedule.numberOfPeriods).fill('');
    
    setWeeklyPrompts(initialPrompts);
    setStep('schedule');
  };

  const handleScheduleSelection = (selectedOption) => {
    setScheduleConfig(selectedOption.config);
    const promptsToUse = selectedGoal.prompts[selectedOption.config.durationType];
    setWeeklyPrompts(Array(selectedOption.config.numberOfPeriods).fill('').map((_, i) => 
      promptsToUse[i] || ''
    ));
    setStep('schedule');
  };

  const handlePromptChange = (index, value) => {
    const newPrompts = [...weeklyPrompts];
    newPrompts[index] = value;
    setWeeklyPrompts(newPrompts);
  };


  const handleDurationTypeChange = (type) => {
    setScheduleConfig(prev => ({
      ...prev,
      durationType: type,
      // Reset schedule type when switching duration type
      scheduleType: type, // This will set it to 'weekly' or 'monthly' based on the duration type
      scheduleDays: [], // Reset selected days
      scheduleWeek: 0 // Reset week selection for monthly
    }));
    
    // Update prompts if needed
    if (selectedGoal.prompts && selectedGoal.prompts[type]) {
      const promptsToUse = selectedGoal.prompts[type];
      setWeeklyPrompts(prev => {
        const newPrompts = [...prev];
        for (let i = 0; i < newPrompts.length; i++) {
          if (promptsToUse[i]) {
            newPrompts[i] = promptsToUse[i];
          }
        }
        return newPrompts;
      });
    }
  };

  const handleScheduleTypeChange = (type) => {
    setScheduleConfig(prev => ({
      ...prev,
      scheduleType: type,
      scheduleDays: [],
      scheduleWeek: type === 'specific_weekday' ? 0 : null
    }));
  };

  const handleDaySelection = (day) => {
    setScheduleConfig(prev => {
      const newDays = prev.scheduleDays.includes(day)
        ? prev.scheduleDays.filter(d => d !== day)
        : [...prev.scheduleDays, day].sort();
      return { ...prev, scheduleDays: newDays };
    });
  };

  const handlePeriodCountChange = (value) => {
    // Allow empty string when user starts typing
    if (value === '') {
      setScheduleConfig(prev => ({
        ...prev,
        numberOfPeriods: ''
      }));
      return;
    }

    // Convert to number and validate
    const numValue = parseInt(value);
    if (isNaN(numValue)) return;

    // Set max based on duration type
    const maxPeriods = scheduleConfig.durationType === 'weekly' ? 52 : 12;
    
    // Clamp value between 1 and max
    const clampedValue = Math.min(Math.max(1, numValue), maxPeriods);
    
    setScheduleConfig(prev => ({
      ...prev,
      numberOfPeriods: clampedValue
    }));
  };


  const handleSubmit = async () => {
    setIsLoading(true);
    try {
        // Prepare schedule data based on schedule type
        let scheduleData = {};
        if (scheduleConfig.scheduleType === 'specific_weekday') {
            scheduleData = {
                schedule_weekday: scheduleConfig.scheduleDays[0],
                schedule_week: scheduleConfig.durationType === 'monthly' ? scheduleConfig.scheduleWeek : null
            };
        } else if (scheduleConfig.scheduleType === 'specific_date') {
            scheduleData = {
                schedule_day: scheduleConfig.scheduleDays[0]
            };
        }

        const challengeData = {
            description: selectedGoal.description,
            category: selectedCategory === 'custom' ? 'Custom' : selectedCategory,
            challenge_level: selectedGoal.level || 'Seeker',
            weekly_prompts: weeklyPrompts,
            duration_type: scheduleConfig.durationType,
            duration_periods: scheduleConfig.numberOfPeriods,
            schedule_type: scheduleConfig.scheduleType,
            schedule_days: scheduleConfig.scheduleDays,
            start_date: scheduleConfig.startDate,
            ...scheduleData
        };

        console.log('Submitting challenge with data:', challengeData);

        const response = await axios.post(
            `${API_ENDPOINTS.SUBMIT_SELECTED_CHALLENGES}/${userId}`,
            {
                selectedChallenges: [challengeData]
            }
        );

        if (response.data.success) {
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
                resetComponent();
            }, 3000);

            onAssessmentComplete();
        } else {
            throw new Error(response.data.error || 'Failed to submit challenge');
        }
    } catch (error) {
        console.error('Error submitting assessment:', error);
        const errorMessage = error.response?.data?.error || 'Failed to submit assessment. Please try again.';
        alert(errorMessage);
    } finally {
        setIsLoading(false);
    }
  };


  const handleCustomGoalSubmit = async () => {
    setIsLoading(true);
    try {
      console.log('Sending custom goal to API:', customGoal);
      const response = await axios.post(`${API_ENDPOINTS.GENERATE_PROMPTS}/${userId}`, { input: customGoal });
      console.log('API Response:', response.data);
      setApiResponse(response.data);
      setSelectedGoal({ 
        description: response.data.recommended_goal, 
        challenge_level: response.data.challenge_level 
      });
      if (response.data.weekly_tasks && response.data.weekly_tasks.length > 0) {
        setWeeklyPrompts(response.data.weekly_tasks);
      } else {
        console.error('No weekly tasks received from API');
        setWeeklyPrompts(['', '', '', '']);
      }
      setStep('schedule'); // Changed from 'customPrompts' to 'schedule'
    } catch (error) {
      console.error('Error generating prompts:', error);
      alert('Failed to generate prompts. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const resetComponent = () => {
    setStep('category');
    setSelectedCategory('');
    setSelectedGoal(null);
    setCustomGoal('');
    setWeeklyPrompts(['', '', '', '']);
    setApiResponse(null);
    setScheduleConfig({
      durationType: 'weekly',
      numberOfPeriods: 4,
      scheduleType: 'weekly',
      scheduleDays: [],
      scheduleWeek: 0,
      startDate: new Date().toISOString().split('T')[0]
    });
  };

  const handleBack = () => {
    switch (step) {
      case 'goal':
      case 'customGoal':
        setStep('category');
        setSelectedCategory('');
        break;
      case 'schedule-selection':
        setStep('goal');
        break;
      case 'schedule':
        setStep(selectedGoal.scheduleOptions ? 'schedule-selection' : 'goal');
        break;
      case 'prompts':
        setStep('schedule');
        break;
      default:
        break;
    }
  };


  // For category selection
  const renderCategorySelection = () => (
    <div className="category-section">
      <h3>Select Your Journey Focus</h3>
      <div className="category-grid">
        {categories.map((category) => (
          <div
            key={category.value}
            className="category-card"
            onClick={() => handleCategorySelect(category.value)}
          >
            <div className="category-icon">
              {getCategoryIcon(category.value)}
            </div>
            <h4>{category.label}</h4>
            <p>{getCategoryDescription(category.value)}</p>
            <div className="category-arrow">→</div>
          </div>
        ))}
        <div
          className="category-card custom-category"
          onClick={() => handleCategorySelect('custom')}
        >
          <div className="category-icon">✨</div>
          <h4>Custom Activity</h4>
          <p>Create your own personalized spiritual goal</p>
          <div className="category-arrow">→</div>
        </div>
      </div>
    </div>
  );



  const renderGoalSelection = () => (
    <div className="goal-section">
      <button className="back-button" onClick={handleBack}>Back</button>
      <h3>Select Your Goal</h3>
      
      {['Seeker', 'Disciple', 'Apostle'].map((level) => (
        <div key={level} className="goal-level-section">
          <h4>{level}</h4>
          <div className="goal-options">
            {categoryGoals[selectedCategory]
              .filter((goal) => goal.level === level)
              .map((goal) => (
                <div
                  key={goal.id}
                  className="goal-option"
                  onClick={() => handleGoalSelect(goal)}
                >
                  <h5>{goal.description}</h5>
                  <p className="goal-prompt-preview">
                    {goal.prompts[goal.defaultSchedule.durationType][0]}
                  </p>
                  <div className="goal-metadata">
                    <span className="goal-duration">
                      {goal.defaultSchedule.durationType === 'weekly' ? 'Weekly' : 'Monthly'} Activity
                    </span>
                    <span className="goal-arrow">→</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );


  const renderScheduleSelection = () => (
    <div className="schedule-selection-section">
      <button className="back-button" onClick={handleBack}>Back</button>
      <h3>Choose Your Schedule</h3>
      <div className="schedule-options-container">
        {selectedGoal.scheduleOptions.map((option, index) => (
          <div
            key={index}
            className="schedule-option-card"
            onClick={() => handleScheduleSelection(option)}
          >
            <div className="schedule-option-icon">
              {option.config.durationType === 'weekly' ? '📅' : '📆'}
            </div>
            <div className="schedule-option-content">
              <h4>{option.label}</h4>
              <p className="schedule-option-description">
                {getScheduleDescription(option.config)}
              </p>
              <div className="schedule-details">
                <span className="duration-tag">
                  {option.config.numberOfPeriods} {option.config.durationType} journey
                </span>
                {option.config.scheduleType === 'specific_weekday' && (
                  <span className="schedule-tag">
                    Fixed days
                  </span>
                )}
              </div>
            </div>
            <div className="schedule-option-arrow">→</div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderScheduleConfiguration = () => (
    <div className="schedule-section">
      <button className="back-button" onClick={handleBack}>Back</button>
      <h3>Configure Schedule</h3>

      <div className="schedule-configuration">
        <div className="schedule-type-section">
          <div className="form-group">
            <label>Duration Type</label>
            <select 
              value={scheduleConfig.durationType}
              onChange={(e) => handleDurationTypeChange(e.target.value)}
              className="schedule-select"
            >
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>

          <div className="form-group">
            <label>Number of {scheduleConfig.durationType === 'weekly' ? 'Weeks' : 'Months'}</label>
            <input
              type="number"
              min="1"
              max={scheduleConfig.durationType === 'weekly' ? 52 : 12}
              value={scheduleConfig.numberOfPeriods}
              onChange={(e) => handlePeriodCountChange(e.target.value)}
              className="period-input"
              onFocus={(e) => e.target.select()}  // Select all text when focused
            />
          </div>
          <div className="form-group">
            <label>Schedule Pattern</label>
            <select
              value={scheduleConfig.scheduleType}
              onChange={(e) => handleScheduleTypeChange(e.target.value)}
              className="schedule-select"
            >
              {scheduleConfig.durationType === 'weekly' ? (
                // Weekly only has one option
                <option value="weekly">Every Week</option>
              ) : (
                // Monthly has all three options
                <>
                  <option value="monthly">Every Month</option>
                  <option value="specific_date">Specific Dates</option>
                  <option value="specific_weekday">Specific Weekday</option>
                </>
              )}
            </select>
          </div>
          
          {scheduleConfig.scheduleType === 'specific_weekday' && (
            <div className="weekday-selection">
              {scheduleConfig.durationType === 'monthly' && (
                <div className="form-group">
                  <label>Week of Month</label>
                  <select
                    value={scheduleConfig.scheduleWeek}
                    onChange={(e) => setScheduleConfig(prev => ({
                      ...prev,
                      scheduleWeek: parseInt(e.target.value)
                    }))}
                    className="week-select"
                  >
                    <option value="0">First</option>
                    <option value="1">Second</option>
                    <option value="2">Third</option>
                    <option value="3">Fourth</option>
                    <option value="-1">Last</option>
                  </select>
                </div>
              )}
              <div className="weekday-selection-container">
                <label>Select Day(s)</label>
                <div className="weekday-buttons">
                  {weekdays.map((day, idx) => (
                    <button
                      key={idx}
                      type="button"
                      className={`weekday-button ${scheduleConfig.scheduleDays.includes(idx) ? 'selected' : ''}`}
                      onClick={() => handleDaySelection(idx)}
                    >
                      {day.substring(0, 3)}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}

          {scheduleConfig.scheduleType === 'specific_date' && 
           scheduleConfig.durationType === 'monthly' && (
            <div className="date-selection">
              <label>Select Date(s) of Month</label>
              <div className="date-grid">
                {Array.from({length: 31}, (_, i) => i + 1).map(date => (
                  <button
                    key={date}
                    type="button"
                    className={`date-button ${scheduleConfig.scheduleDays.includes(date) ? 'selected' : ''}`}
                    onClick={() => handleDaySelection(date)}
                  >
                    {date}
                  </button>
                ))}
              </div>
            </div>
          )}

          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              value={scheduleConfig.startDate}
              onChange={(e) => setScheduleConfig(prev => ({
                ...prev,
                startDate: e.target.value
              }))}
              className="date-input"
            />
          </div>

          <p className="schedule-description">
            Configure how often you'd like to engage with this activity and when you'll begin.
            {scheduleConfig.scheduleType !== 'weekly' && !scheduleConfig.scheduleDays.length && 
             " Please select specific days or dates for your schedule."}
          </p>
        </div>
      </div>

      <button 
        className="submit-button"
        onClick={() => setStep('prompts')}
        disabled={scheduleConfig.numberOfPeriods < 1}
      >
        Continue to Prompts
      </button>
    </div>
  );

  const renderCustomGoalInput = () => (
    <div className="goal-section">
      <button className="back-button" onClick={handleBack}>Back</button>
      <h3>Enter your custom goal:</h3>
      <p className="goal-description">
        Create your own spiritual goal that aligns with your faith journey. Example: "I want to develop a daily prayer routine" or "I want to learn more about Catholic social teaching."
      </p>
      <input
        type="text"
        value={customGoal}
        onChange={(e) => setCustomGoal(e.target.value)}
        placeholder="Enter your custom goal"
      />
      <button
        className="submit-button"
        onClick={handleCustomGoalSubmit}
        disabled={!customGoal || isLoading}
      >
        {isLoading ? 'Generating...' : 'Generate Prompts'}
      </button>
    </div>
  );

  const renderPrompts = () => (
    <div className="prompt-section">
      <button className="back-button" onClick={handleBack}>Back</button>
      
      <div className="goal-header">
        <h3>Your Goal</h3>
        <div className="goal-title">{selectedGoal.description}</div>
        
        <div className="schedule-summary-cards">
          <div className="summary-card">
            <div className="summary-icon">⏱️</div>
            <div className="summary-content">
              <div className="summary-label">Duration</div>
              <div className="summary-value">
                {scheduleConfig.durationType === 'weekly' 
                  ? `${scheduleConfig.numberOfPeriods} week challenge` 
                  : `${scheduleConfig.numberOfPeriods} month challenge`}
              </div>
            </div>
          </div>

          <div className="summary-card">
            <div className="summary-icon">🔄</div>
            <div className="summary-content">
              <div className="summary-label">Check-ins</div>
              <div className="summary-value">
                {scheduleConfig.scheduleType === 'specific_weekday' 
                  ? `${scheduleConfig.durationType === 'monthly' 
                      ? `${weekOptions[scheduleConfig.scheduleWeek]} ` 
                      : ''}${scheduleConfig.scheduleDays.map(day => weekdays[day]).join(', ')}` 
                  : scheduleConfig.scheduleType === 'specific_date'
                    ? `Monthly on: ${scheduleConfig.scheduleDays.join(', ')}`
                    : `Regular ${scheduleConfig.durationType} check-ins`}
              </div>
            </div>
          </div>

          <div className="summary-card">
            <div className="summary-icon">📅</div>
            <div className="summary-content">
              <div className="summary-label">Start Date</div>
              <div className="summary-value">
                {new Date(scheduleConfig.startDate).toLocaleDateString()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="prompts-container">
        <h3>{scheduleConfig.durationType === 'weekly' ? 'Weekly' : 'Monthly'} Prompts</h3>
        <p className="prompts-description">
          Below are your {scheduleConfig.durationType} tasks. You can customize these prompts to better align with your spiritual journey.
        </p>
        
        <div className="prompt-cards">
          {Array.from({ length: scheduleConfig.numberOfPeriods }).map((_, index) => (
            <div key={index} className="prompt-card">
              <div className="prompt-card-header">
                <span className="prompt-number">
                  {scheduleConfig.durationType === 'weekly' ? 'Week' : 'Month'} {index + 1}
                </span>
              </div>
              <textarea
                value={weeklyPrompts[index] || ''}
                onChange={(e) => handlePromptChange(index, e.target.value)}
                placeholder={`Enter your ${scheduleConfig.durationType === 'weekly' ? 'weekly' : 'monthly'} reflection prompt...`}
                className="prompt-textarea"
              />
            </div>
          ))}
        </div>
        
        <button
          className="submit-button"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? 'Submitting...' : 'Start Your Journey'}
        </button>
      </div>
    </div>
  );

  const RegistrationModal = () => (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-icon">✝️</div>
        <h3>Register for a Free Account</h3>
        <p>Register for a free account to set your faith journey activities and grow in your spiritual life.</p>
        <div className="popup-actions">
          <Link 
            to="/signup" 
            className="register-button"
            style={{
              background: 'linear-gradient(135deg, #00b250 0%, #4a90e2 100%)',
              color: 'white',
              padding: '14px 32px',
              borderRadius: '30px',
              textDecoration: 'none',
              fontWeight: '600',
              display: 'inline-block',
              transition: 'all 0.3s ease',
              border: 'none',
              marginBottom: '12px',
              width: '80%',
              textAlign: 'center',
              boxShadow: '0 4px 15px rgba(0, 178, 80, 0.2)'
            }}
          >
            Start Your Journey
          </Link>
          <button 
            onClick={() => setShowRegistrationModal(false)}
            className="later-button"
            style={{
              background: 'none',
              border: 'none',
              color: '#666',
              fontSize: '14px',
              cursor: 'pointer',
              padding: '10px',
              transition: 'color 0.2s'
            }}
          >
            Maybe Later
          </button>
        </div>
        <button 
          className="close-button" 
          onClick={() => setShowRegistrationModal(false)}
          style={{
            position: 'absolute',
            top: '15px',
            right: '20px',
            background: 'none',
            border: 'none',
            fontSize: '24px',
            cursor: 'pointer',
            color: '#666',
            padding: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            transition: 'all 0.2s ease'
          }}
        >
          ×
        </button>
      </div>
    </div>
  );

  return (
    <div className="simplified-assessment">
      {showSuccess ? (
        <div className="success-message">
          <p>
            Challenge added successfully! Check in {
              scheduleConfig.scheduleType === 'specific_weekday' 
                ? `on ${scheduleConfig.scheduleDays.map(day => weekdays[day]).join(', ')}` 
                : scheduleConfig.scheduleType === 'specific_date'
                  ? `on the ${scheduleConfig.scheduleDays.join(', ')} of each month`
                  : `regularly as scheduled`
            }. Reflections and progress will be integrated into your spiritual direction AI.
          </p>
        </div>
      ) : (
        <>
          {showRegistrationModal && <RegistrationModal />}
          {step === 'category' && renderCategorySelection()}
          {step === 'goal' && renderGoalSelection()}
          {step === 'schedule-selection' && renderScheduleSelection()}
          {step === 'schedule' && renderScheduleConfiguration()}
          {step === 'prompts' && renderPrompts()}
          {step === 'customGoal' && renderCustomGoalInput()}
        </>
      )}
    </div>
  );
};

export default SimplifiedAssessment;