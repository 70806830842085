import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import { 
  FaCog, 
  FaTrophy, 
  FaComments, 
  FaUsers, 
  FaCoins, 
  FaLock, 
  FaGlobe, 
  FaUserPlus, 
  FaUserMinus, 
  FaLightbulb,
  FaChevronRight,
  FaEdit,
  FaCalendar
} from 'react-icons/fa';
import { API_ENDPOINTS } from '../../config/api';
import './AdminPanel.css';
import ChallengeEditor from '../../components/ChallengeEditor/ChallengeEditor';

const AdminPanel = ({ groupId }) => {
  const userId = useSelector(state => state.user.userId);
  const [currentChatOverride, setCurrentChatOverride] = useState('');
  const [memberEmail, setMemberEmail] = useState('');
  const [leaderPoints, setLeaderPoints] = useState(0);
  const [activeTab, setActiveTab] = useState('general');
  const [isPublic, setIsPublic] = useState(true);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [customChallengeTitle, setCustomChallengeTitle] = useState('');
  const [weeklyPrompts, setWeeklyPrompts] = useState(['', '', '', '']);
  const [existingChallenges, setExistingChallenges] = useState([]);
  const [samplePrompts, setSamplePrompts] = useState([
    { title: '', description: '' },
    { title: '', description: '' },
    { title: '', description: '' }
  ]);

  const [scheduleConfig, setScheduleConfig] = useState({
    durationType: 'weekly',
    numberOfPeriods: 4, // Set initial value to 4 instead of forcing to 1
    scheduleType: 'weekly',
    scheduleDays: [],
    scheduleWeek: 0,
    startDate: new Date().toISOString().split('T')[0],
    isSelfGuided: false
  });

  const [chatInstructions, setChatInstructions] = useState({
    overview: '',
    openingInstructions: '',
    readingConnectionInstructions: '',
    spiritualGuidanceInstructions: '',
    challengeProgressInstructions: '',
    personalGrowthInstructions: '',
    questionInstructions: '',
    actionStepInstructions: '',
    recommendedPromptsInstructions: '',
  });

  useEffect(() => {
    fetchGroupSettings();
    fetchPendingRequests();
    fetchCurrentChatOverride();
    fetchCurrentSamplePrompts();
    fetchExistingChallenges();
  }, [groupId, userId]);

  useEffect(() => {
    console.log(`Initial window width: ${window.innerWidth}px`);
    console.log(`Initial window height: ${window.innerHeight}px`);

    const handleResize = () => {
      console.log(`Window width: ${window.innerWidth}px`);
      console.log(`Window height: ${window.innerHeight}px`);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchCurrentSamplePrompts = async () => {
    try {
      const url = API_ENDPOINTS.GET_GROUP_SAMPLE_PROMPTS.replace(':groupId', groupId);
      console.log('Fetching sample prompts from:', url);
      const response = await axios.get(url);
      const fetchedPrompts = response.data.prompts;
      
      const filledPrompts = [
        ...fetchedPrompts,
        ...Array(3 - fetchedPrompts.length).fill({ title: '', description: '' })
      ].slice(0, 3);
      
      setSamplePrompts(filledPrompts);
    } catch (error) {
      console.error('Error fetching sample prompts:', error);
    }
  };

  // Updated function to handle duration type changes
  const handleDurationTypeChange = (type) => {
    setScheduleConfig(prev => ({
      ...prev,
      durationType: type,
      numberOfPeriods: type === 'weekly' ? 4 : 3,
      scheduleType: type,
      scheduleDays: []
    }));
    
    // Update prompts array size based on new duration type and periods
    const newPeriodsCount = type === 'weekly' ? 4 : 3;
    setWeeklyPrompts(prev => {
      const newPrompts = Array(newPeriodsCount).fill('');
      // Copy over existing prompts up to the new length
      return prev.slice(0, newPeriodsCount).concat(newPrompts.slice(prev.length));
    });
  };

  const handlePeriodCountChange = (count) => {
    // Allow empty string for clearing input
    if (count === '') {
      setScheduleConfig(prev => ({
        ...prev,
        numberOfPeriods: ''
      }));
      return;
    }

    // Convert to number and validate
    const numCount = parseInt(count);
    if (isNaN(numCount)) {
      return;
    }

    // Validate against max value (24 for both weekly and monthly)
    const validCount = Math.min(Math.max(1, numCount), 24);
    
    setScheduleConfig(prev => ({
      ...prev,
      numberOfPeriods: validCount
    }));

    // Update prompts array to match new period count
    setWeeklyPrompts(prev => {
      const newPrompts = Array(validCount).fill('');
      // Copy over existing prompts
      for (let i = 0; i < Math.min(prev.length, validCount); i++) {
        newPrompts[i] = prev[i];
      }
      return newPrompts;
    });
  };


  const handleScheduleTypeChange = (type) => {
    setScheduleConfig(prev => ({
      ...prev,
      scheduleType: type,
      scheduleDays: [],
      scheduleWeek: type === 'specific_weekday' ? 0 : null
    }));
  };

  const handleDaySelection = (day) => {
    setScheduleConfig(prev => {
      const newDays = prev.scheduleDays.includes(day)
        ? prev.scheduleDays.filter(d => d !== day)
        : [...prev.scheduleDays, day].sort();
      return { ...prev, scheduleDays: newDays };
    });
  };

  const fetchCurrentChatOverride = async () => {
    try {
      const url = API_ENDPOINTS.GET_CHAT_OVERRIDE.replace(':groupId', groupId);
      console.log('Fetching chat override from:', url);
      const response = await axios.get(url);
      setCurrentChatOverride(response.data.chat_override);
      
      try {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data.chat_override, "text/xml");
        
        setChatInstructions({
          overview: xmlDoc.querySelector('overview')?.textContent || '',
          openingInstructions: xmlDoc.querySelector('opening')?.textContent || '',
          readingConnectionInstructions: xmlDoc.querySelector('readingConnection')?.textContent || '',
          spiritualGuidanceInstructions: xmlDoc.querySelector('spiritualGuidance')?.textContent || '',
          challengeProgressInstructions: xmlDoc.querySelector('challengeProgress')?.textContent || '',
          personalGrowthInstructions: xmlDoc.querySelector('personalGrowth')?.textContent || '',
          questionInstructions: xmlDoc.querySelector('question')?.textContent || '',
          actionStepInstructions: xmlDoc.querySelector('actionStep')?.textContent || '',
          recommendedPromptsInstructions: xmlDoc.querySelector('recommendedPrompts')?.textContent || '',
        });
      } catch (parseError) {
        console.error('Error parsing XML:', parseError);
      }
    } catch (error) {
      console.error('Error fetching current chat override:', error);
    }
  };

  const fetchGroupSettings = async () => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.GET_GROUP_SETTINGS.replace(':groupId', groupId)
      );
      setIsPublic(response.data.isPublic);
    } catch (error) {
      console.error('Error fetching group settings:', error);
    }
  };

  const handleChatOverrideSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedOverride = `
      ${chatInstructions.overview}

      <reflection>
          <opening>
          ${chatInstructions.openingInstructions}
          </opening>
          <content>
              <readingConnection>
              ${chatInstructions.readingConnectionInstructions}
              </readingConnection>
              <spiritualGuidance>
              ${chatInstructions.spiritualGuidanceInstructions}
              </spiritualGuidance>
              <challengeProgress>
              ${chatInstructions.challengeProgressInstructions}
              </challengeProgress>
              <personalGrowth>
              ${chatInstructions.personalGrowthInstructions}
              </personalGrowth>
              <question>
              ${chatInstructions.questionInstructions}
              </question>
              <actionStep>
              ${chatInstructions.actionStepInstructions}
              </actionStep>
              <recommendedPrompts>
              ${chatInstructions.recommendedPromptsInstructions}
              </recommendedPrompts>
          </content>
      </reflection>
      `.trim();

      const url = API_ENDPOINTS.UPDATE_GROUP_CHAT_OVERRIDE.replace(':groupId', groupId);
      await axios.put(url, { 
        chat_override: formattedOverride,
        user_id: userId 
      });
      
      alert('Chat override updated successfully');
      fetchCurrentChatOverride();
      
      const pointsResponse = await axios.post(
        API_ENDPOINTS.AWARD_LEADER_POINTS.replace(':userId', userId), 
        { action: 'update_chat_prompt' }
      );
      setLeaderPoints(pointsResponse.data.totalPoints);
    } catch (error) {
      console.error('Error updating chat override:', error);
      alert('Failed to update chat override');
    }
  };

  const handleSamplePromptChange = (index, field, value) => {
    const newPrompts = [...samplePrompts];
    newPrompts[index] = {
      ...newPrompts[index],
      [field]: value
    };
    setSamplePrompts(newPrompts);
  };

  const handleSamplePromptsSubmit = async (e) => {
    e.preventDefault();
    try {
      const validPrompts = samplePrompts.filter(p => p.title && p.description);
      const url = API_ENDPOINTS.UPDATE_GROUP_SAMPLE_PROMPTS.replace(':groupId', groupId);
      
      await axios.post(url, {
        prompts: validPrompts,
        user_id: userId
      });
      
      alert('Sample prompts updated successfully');
      fetchCurrentSamplePrompts();
      
      const pointsResponse = await axios.post(
        API_ENDPOINTS.AWARD_LEADER_POINTS.replace(':userId', userId), 
        { action: 'update_sample_prompts' }
      );
      setLeaderPoints(pointsResponse.data.totalPoints);
    } catch (error) {
      console.error('Error updating sample prompts:', error);
      alert('Failed to update sample prompts');
    }
  };

  const fetchPendingRequests = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.GROUPS}/${groupId}/pending-requests`);
      setPendingRequests(response.data);
    } catch (error) {
      console.error('Error fetching pending requests:', error);
    }
  };

  const fetchExistingChallenges = async () => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.GROUP_CHALLENGES_LIST.replace(':groupId', groupId)
      );
      console.log('Fetched challenges:', response.data);
      response.data.forEach(challenge => {
        console.log('Challenge details:', {
          id: challenge.id,
          title: challenge.title,
          schedule_type: challenge.schedule_type,
          schedule_days: challenge.schedule_days,
          schedule_week: challenge.schedule_week,
          duration_type: challenge.duration_type
        });
      });
      setExistingChallenges(response.data);
    } catch (error) {
      console.error('Error fetching challenges:', error);
    }
  };

  const handleCustomChallengeSubmit = async (e) => {
    e.preventDefault();
    console.log('\n=== Submitting Custom Challenge ===');
    
    try {
        const challengeData = {
            title: customChallengeTitle,
            weeklyPrompts: weeklyPrompts.filter(prompt => prompt.trim()),
            user_id: userId,
            duration_type: scheduleConfig.durationType,
            duration_periods: scheduleConfig.numberOfPeriods,
            schedule_type: scheduleConfig.isSelfGuided ? 'self_guided' : scheduleConfig.scheduleType,
            schedule_days: scheduleConfig.scheduleDays,
            schedule_week: scheduleConfig.scheduleWeek,
            start_date: scheduleConfig.startDate,
            is_self_guided: scheduleConfig.isSelfGuided
        };
        
        console.log('Submitting challenge data:', challengeData);

        const response = await axios.post(
            API_ENDPOINTS.CREATE_CUSTOM_GROUP_CHALLENGE.replace(':groupId', groupId),
            challengeData
        );
        
        console.log('Response:', response.data);
        
        // Reset form
        setCustomChallengeTitle('');
        setWeeklyPrompts(Array(scheduleConfig.numberOfPeriods).fill(''));
        setScheduleConfig({
            durationType: 'weekly',
            numberOfPeriods: 4,
            scheduleType: 'weekly',
            scheduleDays: [],
            scheduleWeek: 0,
            startDate: new Date().toISOString().split('T')[0],
            isSelfGuided: false
        });
        
        alert('Challenge created successfully');
        fetchExistingChallenges();
        
    } catch (error) {
        console.error('Error creating challenge:', error);
        alert('Failed to create challenge. Please try again.');
    }
  };

  const handleInviteMember = async (e) => {
    e.preventDefault();
    try {
      const inviteEndpoint = API_ENDPOINTS.INVITE_TO_GROUP.replace(':groupId', groupId);
      const inviteLink = `${window.location.origin}/signup?groupId=${groupId}`;
      
      const response = await axios.post(inviteEndpoint, {
        email: memberEmail,
        user_id: userId,
        baseUrl: inviteLink
      });

      alert('Invitation sent successfully');
      setMemberEmail('');

      const pointsResponse = await axios.post(
        API_ENDPOINTS.AWARD_LEADER_POINTS.replace(':userId', userId),
        { action: 'invite_member' }
      );
      setLeaderPoints(pointsResponse.data.totalPoints);
    } catch (error) {
      console.error('Error inviting member:', error);
      alert(error.response?.data?.error || 'Failed to send invitation');
    }
  };

  const handlePrivacyToggle = async () => {
    try {
      await axios.put(`${API_ENDPOINTS.GROUPS}/${groupId}/privacy`, { isPublic: !isPublic });
      setIsPublic(!isPublic);
      alert('Group privacy setting updated successfully');
    } catch (error) {
      console.error('Error updating group privacy:', error);
    }
  };

  const handleMemberRequest = async (requestId, approve) => {
    try {
      await axios.post(`${API_ENDPOINTS.GROUPS}/${groupId}/member-request`, { requestId, approve });
      fetchPendingRequests();
      alert(`Member request ${approve ? 'approved' : 'rejected'} successfully`);
    } catch (error) {
      console.error('Error handling member request:', error);
    }
  };

  const handleWeeklyPromptChange = (index, value) => {
    const formattedValue = value.split('.').join('.\n\n').trim();
    const newPrompts = [...weeklyPrompts];
    newPrompts[index] = formattedValue;
    setWeeklyPrompts(newPrompts);
  };

  const renderScheduleConfiguration = () => (
    <div className="schedule-configuration">
      <div className="form-group">
        <label>Challenge Timing</label>
        <select
          value={scheduleConfig.isSelfGuided}
          onChange={(e) => setScheduleConfig(prev => ({
            ...prev,
            isSelfGuided: e.target.value === 'true'
          }))}
          className="schedule-select timing-select"
        >
          <option value="false">Fixed Schedule (Same dates for all participants)</option>
          <option value="true">Self-Guided (Starts when participant joins)</option>
        </select>
        {scheduleConfig.isSelfGuided && (
          <p className="timing-help-text">
            Each participant will receive prompts based on their join date
          </p>
        )}
      </div>

      <div className="form-group">
        <label>Duration Type</label>
        <select 
          value={scheduleConfig.durationType}
          onChange={(e) => handleDurationTypeChange(e.target.value)}
          className="schedule-select"
          disabled={scheduleConfig.isSelfGuided}
        >
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>

      <div className="form-group">

        <label>Number of {scheduleConfig.durationType === 'weekly' ? 'Weeks' : 'Months'}</label>
        <input
          type="number"
          min="1"
          max="24"
          value={scheduleConfig.numberOfPeriods}
          onChange={(e) => handlePeriodCountChange(e.target.value)}
          className="schedule-input"
          required
        />
      </div>

      {!scheduleConfig.isSelfGuided && (
        <>
          <div className="form-group">
            <label>Schedule Type</label>
            <select
              value={scheduleConfig.scheduleType}
              onChange={(e) => handleScheduleTypeChange(e.target.value)}
              className="schedule-select"
            >
              {scheduleConfig.durationType === 'weekly' ? (
                <>
                  <option value="weekly">Regular Weekly</option>
                  <option value="specific_weekday">Specific Days</option>
                </>
              ) : (
                <>
                  <option value="monthly">Regular Monthly</option>
                  <option value="specific_date">Specific Date</option>
                  <option value="specific_weekday">Specific Weekday</option>
                </>
              )}
            </select>
          </div>

          {scheduleConfig.scheduleType === 'specific_weekday' && (
            <div className="weekday-selection">
              {scheduleConfig.durationType === 'monthly' && (
                <div className="form-group">
                  <label>Week of Month</label>
                  <select
                    value={scheduleConfig.scheduleWeek}
                    onChange={(e) => setScheduleConfig(prev => ({
                      ...prev,
                      scheduleWeek: parseInt(e.target.value)
                    }))}
                    className="schedule-select"
                  >
                    <option value="0">First</option>
                    <option value="1">Second</option>
                    <option value="2">Third</option>
                    <option value="3">Fourth</option>
                    <option value="-1">Last</option>
                  </select>
                </div>
              )}
              <div className="weekday-buttons">
                {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, idx) => (
                  <button
                    key={idx}
                    type="button"
                    className={`weekday-button ${scheduleConfig.scheduleDays.includes(idx) ? 'selected' : ''}`}
                    onClick={() => handleDaySelection(idx)}
                  >
                    {day}
                  </button>
                ))}
              </div>
            </div>
          )}

          {scheduleConfig.scheduleType === 'specific_date' && (
            <div className="date-selection">
              <label>Select Date(s) of Month</label>
              <div className="date-grid">
                {Array.from({length: 31}, (_, i) => i + 1).map(date => (
                  <button
                    key={date}
                    type="button"
                    className={`date-button ${scheduleConfig.scheduleDays.includes(date) ? 'selected' : ''}`}
                    onClick={() => handleDaySelection(date)}
                  >
                    {date}
                  </button>
                ))}
              </div>
            </div>
          )}

          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              value={scheduleConfig.startDate}
              onChange={(e) => setScheduleConfig(prev => ({
                ...prev,
                startDate: e.target.value
              }))}
              min={new Date().toISOString().split('T')[0]}
              className="schedule-input"
            />
          </div>
        </>
      )}

      {scheduleConfig.isSelfGuided && (
        <div className="form-group">
          <label>Challenge Start Date</label>
          <input
            type="date"
            value={scheduleConfig.startDate}
            onChange={(e) => setScheduleConfig(prev => ({
              ...prev,
              startDate: e.target.value
            }))}
            min={new Date().toISOString().split('T')[0]}
            className="schedule-input"
          />
          <p className="schedule-help-text">
            This is when the challenge becomes available for participants to join
          </p>
        </div>
      )}
    </div>
  );

  const tabs = [
    { id: 'general', icon: FaCog, label: 'Customize AI Responses' },
    { id: 'challenges', icon: FaTrophy, label: 'Challenges' },
    { id: 'members', icon: FaUsers, label: 'Members Management' }
  ];

  return (
    <div className="admin-panel-container">
      <Header />
      <div className="admin-panel-content">
        <Navbar />
        <div className="admin-panel-main">
          <div className="admin-header">
            <h2><FaCog /> Admin Panel</h2>
            <div className="admin-breadcrumb">
              <span>Dashboard</span>
              <FaChevronRight />
              <span>{tabs.find(tab => tab.id === activeTab)?.label}</span>
            </div>
          </div>

          <div className="admin-layout">
            <div className="admin-sidebar">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`admin-nav-button ${activeTab === tab.id ? 'active' : ''}`}
                >
                  <tab.icon />
                  <span>{tab.label}</span>
                </button>
              ))}
            </div>

            <div className="admin-main-content">
              {activeTab === 'general' && (
                <div className="admin-section">
                  <div className="admin-card instruction-card">
                    <h3><FaComments /> Instructions For Spiritual Direction</h3>
                    <form onSubmit={handleChatOverrideSubmit} className="chat-override-form">
                      <div className="instruction-section">
                        <h4>Reading Connection Instructions</h4>
                        <p className="instruction-description">
                          Specify how to connect scripture or spiritual readings to the user's situation.
                        </p>
                        <textarea
                          value={chatInstructions.readingConnectionInstructions}
                          onChange={(e) => setChatInstructions(prev => ({
                            ...prev,
                            readingConnectionInstructions: e.target.value
                          }))}
                          placeholder="Enter instructions for reading connections..."
                          className="instruction-input"
                        />
                      </div>

                      <div className="instruction-section">
                        <h4>Spiritual Guidance Instructions</h4>
                        <p className="instruction-description">
                          Specify how to connect scripture or spiritual readings to the user's situation.
                        </p>

                        <textarea
                          value={chatInstructions.spiritualGuidanceInstructions}
                          onChange={(e) => setChatInstructions(prev => ({
                            ...prev,
                            spiritualGuidanceInstructions: e.target.value
                          }))}
                          placeholder="Enter instructions for spiritual guidance..."
                          className="instruction-input"
                        />
                      </div>

                      <div className="instruction-section">
                        <h4>Action Step Instructions</h4>
                        <p className="instruction-description">
                          Specify how to connect scripture or spiritual readings to the user's situation.
                        </p>
                        <textarea
                          value={chatInstructions.actionStepInstructions}
                          onChange={(e) => setChatInstructions(prev => ({
                            ...prev,
                            actionStepInstructions: e.target.value
                          }))}
                          placeholder="Enter instructions for suggesting action steps..."
                          className="instruction-input"
                        />
                      </div>

                      <button type="submit" className="primary-button">
                        Update Chat Override
                      </button>
                    </form>

                    {currentChatOverride && (
                      <div className="current-override">
                        <h4>Current Instructions</h4>
                        <div className="override-display">
                          {currentChatOverride}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="admin-card">
                    <h3><FaLightbulb /> Sample Prompts</h3>
                    <form onSubmit={handleSamplePromptsSubmit} className="sample-prompts-form">
                      <div className="prompt-description">
                        Configure up to 3 sample prompts that group members can see in their chat
                      </div>
                      {samplePrompts.map((prompt, index) => (
                        <div key={index} className="sample-prompt-input">
                          <div className="prompt-header">
                            <div className="prompt-number">Prompt {index + 1}</div>
                          </div>
                          <input
                            type="text"
                            value={prompt.title}
                            onChange={(e) => handleSamplePromptChange(index, 'title', e.target.value)}
                            placeholder={`Enter prompt ${index + 1} title`}
                            className="sample-prompt-title"
                          />
                          <textarea
                            value={prompt.description}
                            onChange={(e) => handleSamplePromptChange(index, 'description', e.target.value)}
                            placeholder={`Enter detailed description for prompt ${index + 1}`}
                            className="sample-prompt-description"
                          />
                        </div>
                      ))}
                      <button type="submit" className="primary-button">
                        <FaLightbulb /> Update Sample Prompts
                      </button>
                    </form>
                  </div>
                </div>
              )}

              {activeTab === 'challenges' && (
                <div className="admin-section">
                  <div className="admin-card">
                    <h3><FaTrophy /> Create New Challenge</h3>
                    <form onSubmit={handleCustomChallengeSubmit} className="challenge-form">
                      <div className="form-group">
                        <label>Challenge Title</label>
                        <input
                          type="text"
                          value={customChallengeTitle}
                          onChange={(e) => setCustomChallengeTitle(e.target.value)}
                          placeholder="Enter challenge title"
                          required
                          className="challenge-title-input"
                        />
                      </div>
                      
                      {renderScheduleConfiguration()}
                      
                      <div className="weekly-prompts-container">
                        {Array.from({ length: scheduleConfig.numberOfPeriods }).map((_, index) => (
                          <div key={index} className="form-group">
                            <label>
                              {scheduleConfig.durationType === 'weekly' ? 'Week' : 'Month'} {index + 1} Prompt
                            </label>
                            <textarea
                              value={weeklyPrompts[index] || ''}
                              onChange={(e) => handleWeeklyPromptChange(index, e.target.value)}
                              placeholder={`Enter prompt for ${scheduleConfig.durationType === 'weekly' ? 'week' : 'month'} ${index + 1}`}
                              required
                              className="weekly-prompt-input"
                            />
                          </div>
                        ))}
                      </div>
                      
                      <button type="submit" className="primary-button">
                        <FaTrophy /> Create Challenge
                      </button>
                    </form>
                  </div>

                  <div className="admin-card">
                    <h3><FaEdit /> Edit Existing Challenges</h3>
                    <div className="existing-challenges">
                      {existingChallenges.map(challenge => (
                        <div key={challenge.id} className="challenge-card">
                          <div className="challenge-header">
                            <h4>{challenge.title}</h4>
                            <div className="challenge-timing">
                              <FaCalendar className="timing-icon" />
                              <div className="timing-details">
                                <span className="timing-type">
                                  {challenge.is_self_guided ? (
                                    'Self-Guided Challenge'
                                  ) : (
                                    `Fixed Schedule - ${challenge.duration_type === 'weekly' ? 'Weekly' : 'Monthly'}`
                                  )}
                                </span>
                                {!challenge.is_self_guided && challenge.schedule_type && (
                                  <span className="schedule-details">
                                    {challenge.schedule_type === 'specific_weekday' ? (
                                      <>
                                        {challenge.schedule_week !== undefined && (
                                          `${challenge.schedule_week === -1 ? 'Last' : ['First', 'Second', 'Third', 'Fourth'][challenge.schedule_week]} `
                                        )}
                                        {Array.isArray(challenge.schedule_days) && challenge.schedule_days.length > 0 ? (
                                          challenge.schedule_days.map(day => 
                                            ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day]
                                          ).join(', ')
                                        ) : (
                                          'No days selected'
                                        )}
                                      </>
                                    ) : challenge.schedule_type === 'specific_date' ? (
                                      `Day${challenge.schedule_days?.length > 1 ? 's' : ''} ${challenge.schedule_days?.join(', ') || 'Not set'} of each month`
                                    ) : (
                                      `Every ${challenge.duration_type}`
                                    )}
                                    {challenge.start_date && (
                                      <span className="schedule-start-date">
                                        {' '}(Starting {new Date(challenge.start_date).toLocaleDateString()})
                                      </span>
                                    )}
                                  </span>
                                )}
                                {challenge.is_self_guided && (
                                  <span className="self-guided-note">
                                    Prompts start when participant joins
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="challenge-prompts">
                            {Array.isArray(challenge.weekly_prompts) && challenge.weekly_prompts.map((prompt, index) => (
                              <div key={index} className="prompt-item">
                                <label>
                                  {challenge.duration_type === 'weekly' ? 'Week' : 'Month'} {index + 1} Prompt
                                </label>
                                <p>{prompt}</p>
                              </div>
                            ))}
                          </div>

                          <ChallengeEditor 
                            key={challenge.id}
                            groupId={groupId}
                            challenge={challenge}
                            onUpdate={fetchExistingChallenges}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'members' && (
                <div className="admin-section">
                  <div className="admin-card">
                    <h3><FaUserPlus /> Invite New Member</h3>
                    <form onSubmit={handleInviteMember} className="invite-form">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="email"
                          value={memberEmail}
                          onChange={(e) => setMemberEmail(e.target.value)}
                          placeholder="Enter email address"
                          required
                          className="email-input"
                        />
                        <p className="help-text">
                          An invitation email will be sent with a link to join the group.
                        </p>
                      </div>
                      <button type="submit" className="primary-button">
                        <FaUserPlus /> Send Invitation
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;




