import React, { useState } from 'react';
import { 
  FaChevronRight, 
  FaChevronLeft, 
  FaChevronUp,
  FaChevronDown,
  FaCode,
  FaRobot,
  FaCheckCircle,
  FaCoins,
  FaGraduationCap,
  FaServer
} from 'react-icons/fa';
import './CatholicAppBuilder.css';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import LocalSetup from '../../components/LocalSetup/LocalSetup';
import OpenAISetup from '../../components/OpenAISetup/OpenAISetup';
import BackendSetup from '../../components/BackendSetup/BackendSetup';


const CatholicAppBuilder = () => {
  const [currentSection, setCurrentSection] = useState('local-setup');
  const [completedSections, setCompletedSections] = useState(new Set());
  const [showProposalForm, setShowProposalForm] = useState(false);
  const [proposalData, setProposalData] = useState({
    projectIdea: '',
    desiredSkills: '',
    experience: ''
  });


  const handleProposalChange = (e) => {
    const { name, value } = e.target;
    setProposalData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleProposalSubmit = (e) => {
    e.preventDefault();
    // Handle proposal submission logic here
    console.log('Proposal submitted:', proposalData);
    setShowProposalForm(false);
    setProposalData({
      projectIdea: '',
      desiredSkills: '',
      experience: ''
    });
  };

  const sections = [
    {
      id: 'local-setup',
      title: 'Local Environment',
      icon: <FaCode />,
      component: LocalSetup
    },
    {
      id: 'openai-setup',
      title: 'OpenAI Integration',
      icon: <FaRobot />,
      component: OpenAISetup
    },
    {
      id: 'backend-setup',
      title: 'Backend Deployment',
      icon: <FaServer />,
      component: BackendSetup
    }
  ];


  const handleSectionComplete = (sectionId, taskId) => {
    setCompletedSections(prev => new Set([...prev, `${sectionId}-${taskId}`]));
  };

  const isSectionComplete = (sectionId, taskId) => {
    return completedSections.has(`${sectionId}-${taskId}`);
  };

  const currentSectionIndex = sections.findIndex(s => s.id === currentSection);
  const CurrentSectionComponent = sections[currentSectionIndex].component;

  const navigateSection = (direction) => {
    const newIndex = currentSectionIndex + direction;
    if (newIndex >= 0 && newIndex < sections.length) {
      setCurrentSection(sections[newIndex].id);
    }
  };

  const calculateProgress = () => {
    const totalTasks = {
      'local-setup': 2,
      'openai-setup': 4,
      'backend-setup': 7
    };

    const completedTasks = [...completedSections].reduce((acc, task) => {
      const [section] = task.split('-');
      acc[section] = (acc[section] || 0) + 1;
      return acc;
    }, {});

    let totalProgress = 0;
    let totalSteps = 0;

    Object.keys(totalTasks).forEach(section => {
      totalProgress += completedTasks[section] || 0;
      totalSteps += totalTasks[section];
    });

    return Math.round((totalProgress / totalSteps) * 100);
  };

  return (
    <div className="rewards-container">
      <Header />
      <div className="rewards-content">
        <Navbar />
        <div className="app-builder-container">
          <div className="app-builder-header">
            <h1>Catholic AI Builder Guide</h1>
            <div className="header-content">
              <div className="points-investment-card">
                <FaCoins className="points-icon" />
                <h2>Invest Points in Catholic Innovation</h2>
                <p>Convert your points to collaborate with CrossValidation.ai on consumer applications 
                  that integrate with our platform or stand independently. Members can invest their points 
                  into promising Catholic-aligned projects.</p>
                <button 
                  className="proposal-button"
                  onClick={() => setShowProposalForm(prev => !prev)}
                >
                  {showProposalForm ? 'Hide Proposal Form' : 'Submit Project Proposal'}
                  {showProposalForm ? <FaChevronUp /> : <FaChevronDown />}
                </button>
              </div>

              {showProposalForm && (
                <div className="proposal-form-container">
                  <form className="collaboration-form" onSubmit={handleProposalSubmit}>
                    <h3>Submit Your Collaboration Proposal</h3>
                    <div className="form-group">
                      <label>Project Idea</label>
                      <textarea 
                        name="projectIdea"
                        value={proposalData.projectIdea}
                        onChange={handleProposalChange}
                        placeholder="Describe your Catholic-aligned application idea..."
                        className="project-input"
                      />
                    </div>
                    <div className="form-group">
                      <label>Desired Member Skillset</label>
                      <textarea 
                        name="desiredSkills"
                        value={proposalData.desiredSkills}
                        onChange={handleProposalChange}
                        placeholder="What skills are you looking for in potential collaborators?"
                        className="skills-input"
                      />
                    </div>
                    <div className="form-group">
                      <label>Your Experience</label>
                      <textarea 
                        name="experience"
                        value={proposalData.experience}
                        onChange={handleProposalChange}
                        placeholder="Share your relevant experience and background..."
                        className="experience-input"
                      />
                    </div>
                    <button type="submit" className="submit-proposal-btn">
                      Submit Proposal
                    </button>
                  </form>
                </div>
              )}

              <div className="education-card">
                <FaGraduationCap className="education-icon" />
                <h2>Learn Full-Stack Development</h2>
                <p>Follow our comprehensive guide below to build your own Catholic-aligned application 
                  with AI integration. Perfect for developers looking to create impactful solutions 
                  for the Catholic community.</p>
                <div className="progress-indicator">
                  <div className="progress-bar">
                    <div 
                      className="progress-fill"
                      style={{ width: `${calculateProgress()}%` }}
                    />
                  </div>
                  <span className="progress-text">{calculateProgress()}% Complete</span>
                </div>
              </div>
            </div>
          </div>

          {/* Navigation Steps */}
          <div className="section-navigation">
            {sections.map((section, index) => (
              <button
                key={section.id}
                className={`section-tab ${currentSection === section.id ? 'active' : ''} ${
                  section.id in completedSections ? 'completed' : ''
                }`}
                onClick={() => setCurrentSection(section.id)}
              >
                <div className="section-tab-content">
                  <div className="section-icon">{section.icon}</div>
                  <div className="section-info">
                    <span className="section-number">Step {index + 1}</span>
                    <span className="section-title">{section.title}</span>
                  </div>
                  {section.id in completedSections && (
                    <FaCheckCircle className="section-complete" />
                  )}
                </div>
              </button>
            ))}
          </div>

          {/* Current Section Content */}
          <div className="section-content">
            <CurrentSectionComponent
              onComplete={(taskId) => handleSectionComplete(currentSection, taskId)}
              isCompleted={(taskId) => isSectionComplete(currentSection, taskId)}
            />
          </div>

          {/* Navigation Buttons */}
          <div className="navigation-buttons">
            <button
              className="nav-button prev"
              onClick={() => navigateSection(-1)}
              disabled={currentSectionIndex === 0}
            >
              <FaChevronLeft />
              Previous Step
            </button>
            <button
              className="nav-button next"
              onClick={() => navigateSection(1)}
              disabled={currentSectionIndex === sections.length - 1}
            >
              Next Step
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

};

export default CatholicAppBuilder;