// src/pages/CatholicAppBuilder/components/BackendSetup/BackendSetup.js
import React, { useState } from 'react';
import { 
  FaServer, 
  FaDatabase, 
  FaAws, 
  FaDocker,
  FaCopy, 
  FaCheck, 
  FaGlobe,
  FaLock,
  FaNetworkWired,
  FaTerminal,
  FaCheckCircle,
  FaCog
} from 'react-icons/fa';
import './BackendSetup.css';

const BackendSetup = ({ onComplete, isCompleted }) => {
  const [activeSection, setActiveSection] = useState('aws-setup');
  const [copiedCommand, setCopiedCommand] = useState('');
  const [testResults, setTestResults] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [configValues, setConfigValues] = useState({
    dbUsername: '',
    dbPassword: '',
    domain: '',
    sshKeyName: '',
    ec2Region: 'us-east-1',
    instanceType: 't2.micro'
  });

  const [verificationStatus, setVerificationStatus] = useState({
    'aws-cli': false,
    'ec2-instance': false,
    'rds-setup': false,
    'nginx-config': false,
    'ssl-setup': false,
    'domain-setup': false,
    'flask-deploy': false
  });

  const copyToClipboard = (command, id) => {
    navigator.clipboard.writeText(command);
    setCopiedCommand(id);
    setTimeout(() => setCopiedCommand(''), 2000);
  };

  const handleVerification = (type) => {
    setVerificationStatus(prev => ({
      ...prev,
      [type]: true
    }));
    checkSectionCompletion();
  };

  const checkSectionCompletion = () => {
    const currentSection = sections[activeSection];
    const allStepsCompleted = currentSection.steps.every(
      step => verificationStatus[step.id]
    );
    if (allStepsCompleted) {
      onComplete(activeSection);
    }
  };

  const handleConfigChange = (key, value) => {
    setConfigValues(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const generateEC2UserData = () => {
    return `#!/bin/bash
sudo apt-get update
sudo apt-get install -y python3-pip python3-venv nginx
sudo systemctl start nginx
sudo systemctl enable nginx

# Create application directory
mkdir /var/www/catholic-app
cd /var/www/catholic-app

# Set up Python virtual environment
python3 -m venv venv
source venv/bin/activate
pip install flask flask-cors gunicorn psycopg2-binary openai

# Create Flask application
cat > app.py << 'EOL'
from flask import Flask, request, jsonify
from flask_cors import CORS
import openai

app = Flask(__name__)
CORS(app)

@app.route('/api/health', methods=['GET'])
def health_check():
    return jsonify({"status": "healthy"})

if __name__ == "__main__":
    app.run(host='0.0.0.0')
EOL

# Create Gunicorn service
sudo bash -c 'cat > /etc/systemd/system/catholic-app.service << EOL
[Unit]
Description=Gunicorn instance to serve catholic application
After=network.target

[Service]
User=ubuntu
WorkingDirectory=/var/www/catholic-app
Environment="PATH=/var/www/catholic-app/venv/bin"
ExecStart=/var/www/catholic-app/venv/bin/gunicorn --workers 3 --bind unix:catholic-app.sock -m 007 app:app

[Install]
WantedBy=multi-user.target
EOL'

# Start and enable the service
sudo systemctl start catholic-app
sudo systemctl enable catholic-app`;
  };

  const generateNginxConfig = (domain) => {
    return `server {
    listen 80;
    server_name ${domain || 'your-domain.com'};

    location / {
        root /var/www/catholic-app/build;
        try_files $uri /index.html;
    }

    location /api {
        include proxy_params;
        proxy_pass http://unix:/var/www/catholic-app/catholic-app.sock;
    }
}`;
  };

  const sections = {
    'aws-setup': {
      title: 'AWS Infrastructure Setup',
      icon: <FaAws />,
      steps: [
        {
          id: 'aws-cli',
          title: 'AWS CLI Configuration',
          content: (
            <div className="config-section">
              <div className="command-block">
                <div className="command-header">
                  <span>Install AWS CLI</span>
                  <button
                    className="copy-button"
                    onClick={() => copyToClipboard(
                      'curl "https://awscli.amazonaws.com/awscli-exe-linux-x86_64.zip" -o "awscliv2.zip" && unzip awscliv2.zip && sudo ./aws/install',
                      'aws-cli'
                    )}
                  >
                    {copiedCommand === 'aws-cli' ? <FaCheck /> : <FaCopy />}
                  </button>
                </div>
                <pre>
                  <code>curl "https://awscli.amazonaws.com/awscli-exe-linux-x86_64.zip" -o "awscliv2.zip"
  unzip awscliv2.zip
  sudo ./aws/install</code>
                </pre>
              </div>
              <div className="command-block">
                <div className="command-header">
                  <span>Configure AWS Credentials</span>
                </div>
                <pre>
                  <code>aws configure</code>
                </pre>
                <div className="config-note">
                  Enter your AWS access key, secret key, default region, and output format when prompted
                </div>
              </div>
            </div>
          )
        },
        {
          id: 'ec2-instance',
          title: 'EC2 Instance Setup',
          content: (
            <div className="config-section">
              <div className="form-group">
                <label>SSH Key Name</label>
                <input
                  type="text"
                  value={configValues.sshKeyName}
                  onChange={(e) => handleConfigChange('sshKeyName', e.target.value)}
                  placeholder="my-key-pair"
                />
              </div>
              <div className="form-group">
                <label>Region</label>
                <select
                  value={configValues.ec2Region}
                  onChange={(e) => handleConfigChange('ec2Region', e.target.value)}
                >
                  <option value="us-east-1">US East (N. Virginia)</option>
                  <option value="us-west-2">US West (Oregon)</option>
                  <option value="eu-west-1">EU (Ireland)</option>
                </select>
              </div>
              <div className="form-group">
                <label>Instance Type</label>
                <select
                  value={configValues.instanceType}
                  onChange={(e) => handleConfigChange('instanceType', e.target.value)}
                >
                  <option value="t2.micro">t2.micro (Free tier)</option>
                  <option value="t2.small">t2.small</option>
                  <option value="t2.medium">t2.medium</option>
                </select>
              </div>
              <div className="command-block">
                <div className="command-header">
                  <span>Launch EC2 Instance</span>
                  <button
                    className="copy-button"
                    onClick={() => copyToClipboard(
                      `aws ec2 run-instances --image-id ami-0c55b159cbfafe1f0 --instance-type ${configValues.instanceType} --key-name ${configValues.sshKeyName} --region ${configValues.ec2Region} --user-data "${generateEC2UserData()}"`,
                      'ec2-launch'
                    )}
                  >
                    {copiedCommand === 'ec2-launch' ? <FaCheck /> : <FaCopy />}
                  </button>
                </div>
                <pre>
                  <code>{`aws ec2 run-instances \\
    --image-id ami-0c55b159cbfafe1f0 \\
    --instance-type ${configValues.instanceType} \\
    --key-name ${configValues.sshKeyName} \\
    --region ${configValues.ec2Region} \\
    --user-data file://user-data.sh`}</code>
                </pre>
              </div>
            </div>
          )
        }
      ]
    },
    'database-setup': {
      title: 'RDS Database Setup',
      icon: <FaDatabase />,
      steps: [
        {
          id: 'rds-setup',
          title: 'PostgreSQL RDS Instance',
          content: (
            <div className="config-section">
              <div className="form-group">
                <label>Database Username</label>
                <input
                  type="text"
                  value={configValues.dbUsername}
                  onChange={(e) => handleConfigChange('dbUsername', e.target.value)}
                  placeholder="postgres"
                />
              </div>
              <div className="form-group">
                <label>Database Password</label>
                <input
                  type="password"
                  value={configValues.dbPassword}
                  onChange={(e) => handleConfigChange('dbPassword', e.target.value)}
                  placeholder="Enter secure password"
                />
              </div>
              <div className="command-block">
                <div className="command-header">
                  <span>Create RDS Instance</span>
                  <button
                    className="copy-button"
                    onClick={() => copyToClipboard(
                      `aws rds create-db-instance --db-instance-identifier catholic-db --db-instance-class db.t3.micro --engine postgres --master-username ${configValues.dbUsername} --master-user-password ${configValues.dbPassword} --allocated-storage 20`,
                      'rds-create'
                    )}
                  >
                    {copiedCommand === 'rds-create' ? <FaCheck /> : <FaCopy />}
                  </button>
                </div>
                <pre>
                  <code>{`aws rds create-db-instance \\
    --db-instance-identifier catholic-db \\
    --db-instance-class db.t3.micro \\
    --engine postgres \\
    --master-username ${configValues.dbUsername} \\
    --master-user-password [YOUR-PASSWORD] \\
    --allocated-storage 20`}</code>
                </pre>
              </div>
              <div className="command-block">
                <div className="command-header">
                  <span>Create Database Tables</span>
                </div>
                <pre>
                  <code>{`-- Connect to your RDS instance
  psql -h your-rds-endpoint -U ${configValues.dbUsername} -d postgres

  -- Create tables
  CREATE TABLE users (
      id SERIAL PRIMARY KEY,
      email VARCHAR(255) UNIQUE NOT NULL,
      created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
  );

  CREATE TABLE points (
      id SERIAL PRIMARY KEY,
      user_id INTEGER REFERENCES users(id),
      points DECIMAL(10,2) NOT NULL,
      transaction_type VARCHAR(50),
      description TEXT,
      created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
  );

  CREATE TABLE spiritual_guidance (
      id SERIAL PRIMARY KEY,
      user_id INTEGER REFERENCES users(id),
      assessment TEXT,
      practices JSONB,
      scripture TEXT,
      prayer TEXT,
      next_steps TEXT,
      created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
  );`}</code>
                </pre>
              </div>
            </div>
          )
        }
      ]
    },
    'nginx-setup': {
      title: 'Nginx & SSL Configuration',
      icon: <FaNetworkWired />,
      steps: [
        {
          id: 'nginx-config',
          title: 'Nginx Configuration',
          content: (
            <div className="config-section">
              <div className="form-group">
                <label>Domain Name</label>
                <input
                  type="text"
                  value={configValues.domain}
                  onChange={(e) => handleConfigChange('domain', e.target.value)}
                  placeholder="yourdomain.com"
                />
              </div>
              <div className="command-block">
                <div className="command-header">
                  <span>Create Nginx Configuration</span>
                  <button
                    className="copy-button"
                    onClick={() => copyToClipboard(generateNginxConfig(configValues.domain), 'nginx-config')}
                  >
                    {copiedCommand === 'nginx-config' ? <FaCheck /> : <FaCopy />}
                  </button>
                </div>
                <pre>
                  <code>{generateNginxConfig(configValues.domain)}</code>
                </pre>
              </div>
              <div className="command-block">
                <div className="command-header">
                  <span>Enable Configuration</span>
                </div>
                <pre>
                  <code>{`sudo ln -s /etc/nginx/sites-available/catholic-app /etc/nginx/sites-enabled/
  sudo nginx -t
  sudo systemctl restart nginx`}</code>
                </pre>
              </div>
            </div>
          )
        },
        {
          id: 'ssl-setup',
          title: 'SSL Certificate Setup',
          content: (
            <div className="config-section">
              <div className="command-block">
                <div className="command-header">
                  <span>Install Certbot</span>
                </div>
                <pre>
                  <code>{`sudo apt-get update
  sudo apt-get install certbot python3-certbot-nginx
  sudo certbot --nginx -d ${configValues.domain} -d www.${configValues.domain}`}</code>
                </pre>
              </div>
              <div className="command-block">
                <div className="command-header">
                  <span>Auto-renewal Configuration</span>
                </div>
                <pre>
                  <code>{`sudo certbot renew --dry-run
  sudo systemctl status certbot.timer`}</code>
                </pre>
              </div>
            </div>
          )
        }
      ]
    },
    'domain-setup': {
      title: 'Domain & DNS Configuration',
      icon: <FaGlobe />,
      steps: [
        {
          id: 'domain-setup',
          title: 'GoDaddy DNS Configuration',
          content: (
            <div className="config-section">
              <div className="instruction-block">
                <h4>Steps to Configure Domain:</h4>
                <ol>
                  <li>Log in to your GoDaddy account</li>
                  <li>Navigate to DNS Management for {configValues.domain}</li>
                  <li>Add the following records:</li>
                </ol>
                <table className="dns-table">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Host</th>
                      <th>Points To</th>
                      <th>TTL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>A</td>
                      <td>@</td>
                      <td>[Your EC2 IP]</td>
                      <td>600</td>
                    </tr>
                    <tr>
                      <td>CNAME</td>
                      <td>www</td>
                      <td>@</td>
                      <td>600</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button
                className="verify-button"
                onClick={() => handleVerification('domain-setup')}
              >
                Verify DNS Configuration
              </button>
            </div>
          )
        }
      ]
    }
  };

  return (
    <div className="backend-setup">
      <div className="setup-sections">
        {Object.entries(sections).map(([id, section]) => (
          <button
            key={id}
            className={`section-tab ${activeSection === id ? 'active' : ''} ${
              isCompleted(id) ? 'completed' : ''
            }`}
            onClick={() => setActiveSection(id)}
          >
            {section.icon}
            <span>{section.title}</span>
            {isCompleted(id) && <FaCheckCircle className="completed-icon" />}
          </button>
        ))}
      </div>

      <div className="section-content">
        <h3 className="section-title">
          {sections[activeSection].icon}
          {sections[activeSection].title}
        </h3>
        
        <div className="steps-container">
          {sections[activeSection].steps.map((step) => (
            <div key={step.id} className="setup-step">
              <h4 className="step-title">
                {step.title}
                {verificationStatus[step.id] && (
                  <FaCheckCircle className="step-complete-icon" />
                )}
              </h4>
              {step.content}
            </div>
          ))}
        </div>
      </div>

      <div className="setup-progress">
        <div className="progress-bar">
          <div 
            className="progress-fill"
            style={{
              width: `${
                Object.values(verificationStatus).filter(Boolean).length * (100 / Object.keys(verificationStatus).length)
              }%`
            }}
          />
        </div>
        <span className="progress-text">
          {Object.values(verificationStatus).filter(Boolean).length} of {Object.keys(verificationStatus).length} steps completed
        </span>
      </div>
    </div>
  );
};

export default BackendSetup;