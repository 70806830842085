import React, { useState, useEffect } from 'react';
import { FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';

const ChallengeEditor = ({ groupId, challenge, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [weeklyPrompts, setWeeklyPrompts] = useState(challenge.weekly_prompts || []);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        API_ENDPOINTS.UPDATE_GROUP_CHALLENGE.replace(':groupId', groupId).replace(':challengeId', challenge.id),
        {
          weekly_prompts: weeklyPrompts
        }
      );
      
      if (response.data) {
        onUpdate(response.data);
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Error updating challenge:', error);
      alert('Failed to update challenge prompts');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePromptChange = (index, value) => {
   const newPrompts = [...weeklyPrompts];
   // Keep the raw value with original line breaks
   newPrompts[index] = value.trim(); 
   
   // Optional - only if you want to ensure consistent spacing after periods
   // while preserving user line breaks
   newPrompts[index] = value.replace(/\.(?!\n)/g, '.\n').trim();
   
   setWeeklyPrompts(newPrompts);
  };

  return (
    <div className="bg-white rounded-lg shadow p-4 mb-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">{challenge.title}</h3>
        {!isEditing ? (
          <button
            onClick={() => setIsEditing(true)}
            className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
          >
            <FaEdit /> Edit Prompts
          </button>
        ) : (
          <div className="flex gap-2">
            <button
              onClick={handleSave}
              disabled={isLoading}
              className="flex items-center gap-2 text-green-600 hover:text-green-800"
            >
              <FaSave /> Save
            </button>
            <button
              onClick={() => setIsEditing(false)}
              className="flex items-center gap-2 text-red-600 hover:text-red-800"
            >
              <FaTimes /> Cancel
            </button>
          </div>
        )}
      </div>

      <div className="space-y-4">
        {weeklyPrompts.map((prompt, index) => (
          <div key={index} className="border rounded p-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Week {index + 1} Prompt
            </label>
            {isEditing ? (
              <textarea
                value={prompt}
                onChange={(e) => handlePromptChange(index, e.target.value)}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                rows={3}
              />
            ) : (
              <p className="text-gray-600">{prompt}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChallengeEditor;