import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserId } from '../../store/actions/userActions';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import Research from '../Research/Research';
import Invest from '../Invest/Invest';
import Monitor from '../Monitor/Monitor';
import { FaSearch, FaChartLine, FaChartBar } from 'react-icons/fa';
import './PublicInvest.css';

const PublicInvest = () => {
  const { isAuthorized, userId, email } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('research');

  useEffect(() => {
    if (isAuthorized && email && !userId) {
      dispatch(fetchUserId(email));
    }
  }, [isAuthorized, email, userId, dispatch]);

  return (
    <div className="public-invest-container">
      <Header />
      <div className="public-invest-content">
        <Navbar />
        <div className="public-invest-main">
          <section className="page-header">
            <h1>Catholic Aligned Gen-AI Investment Platform</h1>
            <p className="header-description">
              Discover and invest in leading public companies that align with Catholic values 
              and demonstrate strong Generative AI capabilities. Our systematic approach 
              analyzes the top 250 market cap companies to help you make informed, 
              values-based investment decisions.
            </p>
          </section>

          <section className="tabs-container">
            <div className="tab-buttons">
              <button 
                onClick={() => setActiveTab('research')} 
                className={activeTab === 'research' ? 'active' : ''}
              >
                <FaSearch /> Research
              </button>
              <button 
                onClick={() => setActiveTab('invest')} 
                className={activeTab === 'invest' ? 'active' : ''}
              >
                <FaChartLine /> Invest
              </button>
              <button 
                onClick={() => setActiveTab('monitor')} 
                className={activeTab === 'monitor' ? 'active' : ''}
              >
                <FaChartBar /> Monitor
              </button>
            </div>

            <div className="tab-content">
              {activeTab === 'research' && (
                <div className="tab-pane">
                  <Research />
                </div>
              )}
              {activeTab === 'invest' && (
                <div className="tab-pane">
                  <Invest />
                </div>
              )}
              {activeTab === 'monitor' && (
                <div className="tab-pane">
                  <Monitor />
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PublicInvest;