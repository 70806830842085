import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { logInSchema } from "../../schemas/userSchemas";
import { signIn, clearSignUpErrors } from "../../store/actions/userActions";
import Swal from "sweetalert2";
import { FaEnvelope, FaEye, FaEyeSlash } from 'react-icons/fa';
import "./SignIn.css";
import BtnLoader from "../../components/BtnLoader/BtnLoader";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Navbar/Navbar";

const SignIn = () => {
  console.log('SignIn component rendered');
  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated, error, loading, userId } = useSelector((state) => {
    console.log('Redux state:', state.user);
    return state.user;
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log('Current auth state:', { isAuthenticated, userId, loading, error });

  const handleLogIn = async (values) => {
    console.log('HandleLogIn called with values:', values);
    try {
      const data = {
        email: values.email,
        password: values.password,
      };
      
      console.log('Dispatching signIn with data:', { email: data.email });
      const result = await dispatch(signIn(data));
      console.log('SignIn dispatch completed with result:', result);
      
      if (result?.payload?.userId) {
        console.log('Login successful, navigating to chat with userId:', result.payload.userId);
        navigate("/chat", { 
          state: { 
            newUser: false, 
            userId: result.payload.userId 
          } 
        });
      }
    } catch (error) {
      console.error('HandleLogIn error:', error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error}`,
      });
    }
  };

  const { handleChange, handleBlur, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: handleLogIn,
    validationSchema: logInSchema,
    validateOnChange: false,
    validateOnBlur: true,
  });

  // Modified navigation effect - also checks for just userId
  useEffect(() => {
    console.log('Navigation effect triggered:', { isAuthenticated, userId });
    if (userId) {
      console.log('userId present, attempting navigation');
      navigate("/chat", { state: { newUser: false, userId: userId } });
    }
  }, [userId, navigate]);

  useEffect(() => {
    console.log('Error effect triggered:', { error });
    if (error) {
      console.log('Showing error alert:', error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error}`,
      });
      dispatch(clearSignUpErrors());
    }
  }, [dispatch, error]);

  // Rest of your render code remains the same
  return (
    <div className="signin-container">
      <Header />
      <div className="signin-content">
        <Navbar />
        <div className="signin-main">
          <div className="signin-card">
            <h1 className="signin-title">Sign In to Your Account</h1>
            <form className="signin-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="user-email">
                  Email <span className="required-field">*</span>
                </label>
                <div className={`input-container ${errors.email ? 'error' : ''}`}>
                  <div className="icon-container">
                    <FaEnvelope />
                  </div>
                  <input
                    type="email"
                    name="email"
                    id="user-email"
                    placeholder="Enter your email address"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.email && <span className="error-msg">{errors.email}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="user-password">
                  Password <span className="required-field">*</span>
                </label>
                <div className={`input-container ${touched.password && errors.password ? 'error' : ''}`}>
                  <div className="icon-container">
                    {showPassword ? (
                      <FaEyeSlash className="clickable" onClick={() => setShowPassword(!showPassword)} />
                    ) : (
                      <FaEye className="clickable" onClick={() => setShowPassword(!showPassword)} />
                    )}
                  </div>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="user-password"
                    placeholder="Enter your password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.password && errors.password && <span className="error-msg">{errors.password}</span>}
              </div>

              <button type="submit" className="signin-submit-btn" disabled={loading}>
                {loading ? <BtnLoader /> : "Sign In"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;