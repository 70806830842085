// src/pages/CatholicAppBuilder/components/LocalSetup/LocalSetup.js
import React, { useState } from 'react';
import { 
  FaTerminal, 
  FaCheck, 
  FaNodeJs, 
  FaPython, 
  FaDownload, 
  FaCode,
  FaCopy,
  FaCheckCircle
} from 'react-icons/fa';
import './LocalSetup.css';

const LocalSetup = ({ onComplete, isCompleted }) => {
  const [activeTab, setActiveTab] = useState('python');
  const [copiedCommand, setCopiedCommand] = useState('');
  const [verificationStatus, setVerificationStatus] = useState({
    python: false,
    pip: false,
    node: false,
    npm: false,
    react: false
  });

  const copyToClipboard = (command, id) => {
    navigator.clipboard.writeText(command);
    setCopiedCommand(id);
    setTimeout(() => setCopiedCommand(''), 2000);
  };

  const handleVerification = (type) => {
    setVerificationStatus(prev => ({
      ...prev,
      [type]: true
    }));

    // Check if all verifications in a group are complete
    if (type === 'npm' && verificationStatus.node) {
      onComplete('node');
    }
    if (type === 'pip' && verificationStatus.python) {
      onComplete('python');
    }
  };

  const tasks = {
    python: [
      {
        id: 'python-install',
        title: 'Install Python',
        commands: [
          {
            id: 'python-download',
            type: 'link',
            text: 'Download Python',
            url: 'https://www.python.org/downloads/',
            icon: <FaDownload />
          },
          {
            id: 'python-verify',
            type: 'command',
            text: 'python --version',
            description: 'Verify Python installation'
          },
          {
            id: 'pip-install',
            type: 'command',
            text: 'curl https://bootstrap.pypa.io/get-pip.py -o get-pip.py && python get-pip.py',
            description: 'Install pip if not included'
          },
          {
            id: 'pip-verify',
            type: 'command',
            text: 'pip --version',
            description: 'Verify pip installation'
          }
        ]
      }
    ],
    node: [
      {
        id: 'node-install',
        title: 'Install Node.js',
        commands: [
          {
            id: 'node-download',
            type: 'link',
            text: 'Download Node.js',
            url: 'https://nodejs.org/',
            icon: <FaDownload />
          },
          {
            id: 'node-verify',
            type: 'command',
            text: 'node --version',
            description: 'Verify Node.js installation'
          },
          {
            id: 'npm-verify',
            type: 'command',
            text: 'npm --version',
            description: 'Verify npm installation'
          }
        ]
      },
      {
        id: 'react-setup',
        title: 'Create React App',
        commands: [
          {
            id: 'create-react-app',
            type: 'command',
            text: 'npx create-react-app catholic-app',
            description: 'Create new React application'
          },
          {
            id: 'cd-app',
            type: 'command',
            text: 'cd catholic-app',
            description: 'Navigate to app directory'
          },
          {
            id: 'start-app',
            type: 'command',
            text: 'npm start',
            description: 'Start development server'
          }
        ]
      }
    ]
  };

  return (
    <div className="local-setup">
      <div className="setup-tabs">
        <button
          className={`tab ${activeTab === 'python' ? 'active' : ''}`}
          onClick={() => setActiveTab('python')}
        >
          <FaPython /> Python Setup
        </button>
        <button
          className={`tab ${activeTab === 'node' ? 'active' : ''}`}
          onClick={() => setActiveTab('node')}
        >
          <FaNodeJs /> Node.js & React Setup
        </button>
      </div>

      <div className="setup-content">
        {tasks[activeTab].map((task) => (
          <div key={task.id} className="setup-card">
            <h3 className="setup-card-title">
              {task.title}
              {isCompleted(task.id) && <FaCheckCircle className="completed-icon" />}
            </h3>
            
            <div className="command-list">
              {task.commands.map((cmd) => (
                <div key={cmd.id} className="command-item">
                  {cmd.type === 'link' ? (
                    <a
                      href={cmd.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="download-link"
                    >
                      {cmd.icon} {cmd.text}
                    </a>
                  ) : (
                    <div className="command-block">
                      <div className="command-header">
                        <span className="command-description">{cmd.description}</span>
                        <button
                          className="copy-button"
                          onClick={() => copyToClipboard(cmd.text, cmd.id)}
                        >
                          {copiedCommand === cmd.id ? <FaCheck /> : <FaCopy />}
                        </button>
                      </div>
                      <div className="command-content">
                        <code>{cmd.text}</code>
                      </div>
                      <button
                        className={`verify-button ${
                          verificationStatus[cmd.id] ? 'verified' : ''
                        }`}
                        onClick={() => handleVerification(cmd.id)}
                      >
                        {verificationStatus[cmd.id] ? (
                          <>
                            <FaCheck /> Verified
                          </>
                        ) : (
                          'Verify Installation'
                        )}
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="setup-progress">
        <div className="progress-bar">
          <div 
            className="progress-fill"
            style={{
              width: `${
                Object.values(verificationStatus).filter(Boolean).length * 20
              }%`
            }}
          />
        </div>
        <span className="progress-text">
          {Object.values(verificationStatus).filter(Boolean).length} of 5 steps completed
        </span>
      </div>
    </div>
  );
};

export default LocalSetup;