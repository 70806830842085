import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { fetchUserId } from '../../store/actions/userActions';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import { API_ENDPOINTS } from '../../config/api';
import { 
  FaCoins, 
  FaChartLine,
  FaInfoCircle,
  FaSpinner
} from 'react-icons/fa';
import './Rewards.css';

const Rewards = () => {
  const { isAuthorized, userId, email, tempUserId } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [currentPoints, setCurrentPoints] = useState(0);
  const [isLoadingPoints, setIsLoadingPoints] = useState(true);
  const [showAddPointsModal, setShowAddPointsModal] = useState(false);

  const effectiveUserId = userId || tempUserId;

  useEffect(() => {
    if (isAuthorized && email && !userId) {
      dispatch(fetchUserId(email));
    }
  }, [isAuthorized, email, userId, dispatch]);

  useEffect(() => {
    const fetchPoints = async () => {
      if (effectiveUserId) {
        try {
          console.log('Fetching points for user:', effectiveUserId);
          // Use API_ENDPOINTS.USER_POINTS directly
          const response = await axios.get(`${API_ENDPOINTS.USER_POINTS}/${effectiveUserId}`);
          console.log('Points response:', response.data);
          
          if (response.data && response.data.current_points !== undefined) {
            setCurrentPoints(response.data.current_points);
          }
        } catch (error) {
          console.error('Error fetching points:', error);
          console.error('Full error details:', {
            message: error.message,
            status: error.response?.status,
            data: error.response?.data
          });
        } finally {
          setIsLoadingPoints(false);
        }
      }
    };

    fetchPoints();
  }, [effectiveUserId]);

  // Rest of the component remains exactly the same
  const formatPoints = (points) => parseFloat(points || 0).toFixed(1);

  const handleAddPoints = () => {
    setShowAddPointsModal(true);
  };

  const navigateToInvestment = (type) => {
    switch(type) {
      case 'public':
        navigate('/public-invest');
        break;
      case 'private':
        navigate('/private-investment');
        break;
      case 'build':
        navigate('/builder');
        break;
      default:
        break;
    }
  };

  const renderMetricsSection = () => (
    <div className="dashboard-metrics">
      <div className="metric-card current-points">
        <div className="metric-icon">
          <FaCoins />
        </div>
        <div className="metric-content">
          <h3>Available Points</h3>
          {isLoadingPoints ? (
            <span className="metric-value loading">
              <FaSpinner className="spinner" />
            </span>
          ) : (
            <span className="metric-value">{formatPoints(currentPoints)}</span>
          )}
        </div>
      </div>
      
      <div className="metric-card investment-amount">
        <div className="metric-icon">
          <FaChartLine />
        </div>
        <div className="metric-content">
          <h3>Amount Invested</h3>
          <span className="metric-value">$0.00</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="rewards-container">
      <Header />
      <div className="rewards-content">
        <Navbar />
        <div className="rewards-main">
          <section className="rewards-header">
            <h1>Catholic Aligned Investing</h1>
            <div className="rewards-subheader">
              <p className="conversion-rate">
                <FaInfoCircle className="info-icon" />
                1 Point = $1 Investment Value
              </p>
            </div>
          </section>

          <section className="metrics-investment-wrapper">
            {renderMetricsSection()}

            <div className="investment-section">
              <div 
                className="investment-card featured"
                onClick={() => navigateToInvestment('public')}
              >
                <div className="investment-content">
                  <div className="investment-header">
                    <div className="investment-icon">
                      <FaChartLine />
                    </div>
                    <h3>Public Market AI Investing</h3>
                  </div>
                  <p>Convert your points to invest in Catholic-aligned public market stocks through your Schwab account</p>
                  <button className="invest-now-btn">Start Investing</button>
                </div>
              </div>
            </div>
          </section>

          <section className="rewards-info">
            <h2>How to Earn Points</h2>
            <div className="rewards-cards">
              <div className="reward-card faith-activities">
                <h3>
                  <FaCoins className="card-icon" />
                  Faith Activities
                </h3>
                <ul>
                  <li>Gospel Reflection: 0.1 points</li>
                  <li>Weekly Check-in: 0.25 points</li>
                  <li>Additional Reflection: 0.1 points</li>
                  <li>Complete Challenge: 1.0 points</li>
                </ul>
              </div>
              
              <div className="reward-card community">
                <h3>
                  <FaCoins className="card-icon" />
                  Community Engagement
                </h3>
                <ul>
                  <li>Create Group: 0.5 points</li>
                  <li>Join Group: 0.25 points</li>
                  <li>Group Comment: 0.1 points</li>
                  <li>Create Group Challenge: 0.25 points</li>
                </ul>
              </div>
              
              <div className="reward-card goals">
                <h3>
                  <FaCoins className="card-icon" />
                  Goals & Referrals
                </h3>
                <ul>
                  <li>Initial Referral: 1.0 points</li>
                  <li>Monthly Referral Bonus: 2.0 points</li>
                  <li>Create Challenge: 0.25 points</li>
                  <li>Create Personal Goal: 0.25 points</li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Rewards;