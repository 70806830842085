import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { FaChartLine, FaTasks, FaCalendarCheck, FaLock, FaSpinner, FaUsers, FaPlusCircle, FaList, FaCalendar } from 'react-icons/fa';
import { API_ENDPOINTS } from '../../config/api';
import './ChallengesDashboard.css';

const ChallengesDashboard = ({ 
  challenges, 
  personalGoals,
  userLevels, 
  onChallengeUpdate, 
  onPersonalGoalUpdate,
  onInitiateNextChallenges,
  userId
}) => {
  const [expandedChallenge, setExpandedChallenge] = useState(null);
  const [reflections, setReflections] = useState({});
  const [loading, setLoading] = useState({});
  const [localReflections, setLocalReflections] = useState({});
  const [groupNames, setGroupNames] = useState({});
  const [groupChallengesLoaded, setGroupChallengesLoaded] = useState(false);
  const [weeklyReflections, setWeeklyReflections] = useState({}); 
  const [showAllReflections, setShowAllReflections] = useState({});
  const [shareAsComment, setShareAsComment] = useState({});


  const [scheduleConfig, setScheduleConfig] = useState({
    durationType: 'weekly',
    numberOfPeriods: 4,
    scheduleType: 'weekly',
    scheduleDays: [],
    scheduleWeek: 0,
    startDate: new Date().toISOString().split('T')[0]
  });


  const sampleChallenge = {
    id: 'sample-challenge',
    description: 'Attend digital gospel event November 3rd. Pray about the discussion then add a reflection.',
    category: 'Custom Goals.',
    period_prompts: [
      'Prompt CrossValidation.ai Spiritual Director for gospel verses related to a challenge faced today.',
      'Read the November 3rd Sunday gospel to reflect on the repetence of Zacchaeus, his obstacles, and the message from Jesus. How does this relate to your life?',
      'Set a weekly scripture reading and reflection routine. Tie is into your daily activities.',
      'Attend a gospel reflection and share your thoughts with a friend. Invite them to discuss a Sunday gospel.'
    ],
    period_progress: ['not_started', 'not_started', 'not_started', 'not_started'],
    start_date: moment().format('YYYY-MM-DD')
  };



  // Add this helper function for monthly weekday date calculations
  const getMonthlyWeekdayDate = (year, month, weekNum, weekday) => {
    const date = moment().year(year).month(month).startOf('month');
    if (weekNum === -1) {
      return date.endOf('month').day(weekday - 7);
    }
    return date.day(weekNum * 7 + weekday);
  };

  // Add this function for check-in validation
  const isValidCheckInDate = (challenge, currentDate = moment()) => {
    if (challenge.schedule_type === 'specific_weekday') {
      if (challenge.duration_type === 'monthly') {
        const targetDate = getMonthlyWeekdayDate(
          currentDate.year(),
          currentDate.month(),
          challenge.schedule_week,
          challenge.schedule_days[0]
        );
        return Math.abs(currentDate.diff(targetDate, 'days')) <= 1;
      }
      return challenge.schedule_days.includes(currentDate.day());
    }
    
    if (challenge.schedule_type === 'specific_date') {
      return challenge.schedule_days.includes(currentDate.date());
    }
    
    const periodStart = moment(challenge.start_date)
      .add(challenge.current_period * (challenge.duration_type === 'weekly' ? 7 : 30), 'days');
    return currentDate.isBetween(periodStart, periodStart.clone().add(1, challenge.duration_type), 'day', '[]');
  };




  useEffect(() => {
    console.log('==================== Initial Challenge Data ====================');
    console.log('Raw challenges:', challenges);
    console.log('Raw personal goals:', personalGoals);
    console.log('User levels:', userLevels);
    const groupChallenges = challenges.filter(c => c.group_id);
    console.log('Group challenges found:', groupChallenges);
    console.log('Group names:', groupNames);
  }, [challenges, personalGoals, userLevels, groupNames]);

  useEffect(() => {
    const fetchWeeklyReflections = async (challengeId) => {
      if (!challengeId || !userId) return;
      
      try {
        const challenge = challenges.find(c => c.id === challengeId);
        if (!challenge?.group_id) return;
        
        const response = await axios.get(
          API_ENDPOINTS.GROUP_CHALLENGE_REFLECTIONS
            .replace(':groupId', challenge.group_id)
            .replace(':challengeId', challengeId)
            .replace(':userId', userId)
        );
        
        if (response.data && response.data.reflections) {
          setWeeklyReflections(prev => ({
            ...prev,
            [challengeId]: response.data.reflections
          }));
        }
      } catch (error) {
        console.error('Error fetching weekly reflections:', error);
      }
    };

    if (expandedChallenge) {
      fetchWeeklyReflections(expandedChallenge);
    }
  }, [expandedChallenge, userId, challenges]);

  useEffect(() => {
    const fetchGroupChallenges = async () => {
      if (groupChallengesLoaded) return;

      try {
        const groupsResponse = await axios.get(`${API_ENDPOINTS.GET_GROUPS}?user_id=${userId}`);
        const userGroups = groupsResponse.data.filter(group => group.is_member);
        
        const groupChallenges = await Promise.all(
          userGroups.map(async (group) => {
            try {
              const endpoint = `${API_ENDPOINTS.GROUP_CHALLENGES_LIST.replace(':groupId', group.id)}?userId=${userId}`;
              const response = await axios.get(endpoint);
              
              return response.data
                .filter(challenge => challenge.is_participating)
                .map(challenge => ({
                  ...challenge,
                  group_id: group.id,
                  group_name: group.name,
                  isGroupChallenge: true,
                  category: 'Group Challenges'
                }));
            } catch (error) {
              console.error(`Error fetching challenges for group ${group.id}:`, error);
              return [];
            }
          })
        );

        const allGroupChallenges = groupChallenges.flat();
        
        if (allGroupChallenges.length > 0) {
          onChallengeUpdate(prevChallenges => {
            if (!prevChallenges) return allGroupChallenges;
            const nonGroupChallenges = prevChallenges.filter(c => !c.group_id && !c.isGroupChallenge);
            const newChallenges = [...nonGroupChallenges, ...allGroupChallenges];
            if (JSON.stringify(prevChallenges) === JSON.stringify(newChallenges)) {
              return prevChallenges;
            }
            return newChallenges;
          });
        }
        setGroupChallengesLoaded(true);
      } catch (error) {
        console.error('Error fetching group challenges:', error);
      }
    };

    if (userId && !groupChallengesLoaded) {
      fetchGroupChallenges();
    }
  }, [userId, groupChallengesLoaded, onChallengeUpdate]);

  useEffect(() => {
    const fetchGroupNames = async () => {
      try {
        const groupIds = [...new Set(challenges.filter(c => c.group_id).map(c => c.group_id))];
        if (groupIds.length > 0) {
          const response = await axios.get(`${API_ENDPOINTS.GET_GROUPS}?user_id=${userId}`);
          const groupsMap = {};
          response.data.forEach(group => {
            if (groupIds.includes(group.id)) {
              groupsMap[group.id] = group.name;
            }
          });
          setGroupNames(groupsMap);
        } else {
          setGroupNames({});
        }
      } catch (error) {
        console.error('Error processing group IDs:', error);
        setGroupNames({});
      }
    };

    fetchGroupNames();
  }, [challenges, userId]);

  const getNextCheckInDate = (challenge) => {
    const today = moment();
    
    if (challenge.schedule_type === 'specific_weekday') {
      if (challenge.duration_type === 'monthly') {
        const targetWeek = challenge.schedule_week === -1 ? 'last' : challenge.schedule_week + 1;
        const targetDay = challenge.schedule_days[0];
        return moment().startOf('month')
          .day(targetWeek === 'last' ? -1 : 0)
          .day(targetDay);
      } else {
        const nextDays = challenge.schedule_days
          .map(day => {
            const next = moment().day(day);
            if (next.isBefore(today)) {
              next.add(1, 'week');
            }
            return next;
          })
          .sort((a, b) => a - b);
        return nextDays[0];
      }
    } else if (challenge.schedule_type === 'specific_date' && challenge.duration_type === 'monthly') {
      const targetDate = challenge.schedule_days[0];
      let nextDate = moment().date(targetDate);
      if (nextDate.isBefore(today)) {
        nextDate = nextDate.add(1, 'month');
      }
      return nextDate;
    } else {
      const periodLength = challenge.duration_type === 'weekly' ? 7 : 30;
      return moment(challenge.start_date).add(periodLength * challenge.current_period, 'days');
    }
  };

  const getPeriodDates = (challenge, periodIndex) => {
    if (!challenge.start_date) return '';
    
    const start = moment(challenge.start_date);
    const periodLength = challenge.duration_type === 'weekly' ? 'week' : 'month';
    
    let periodStart = start.clone().add(periodIndex, periodLength);
    let periodEnd;

    if (challenge.schedule_type === 'specific_weekday') {
      if (challenge.duration_type === 'monthly') {
        const weekNum = challenge.schedule_week === -1 ? 'last' : challenge.schedule_week + 1;
        periodEnd = periodStart.clone().endOf('month');
        const weekday = challenge.schedule_days[0];
        if (weekNum === 'last') {
          periodStart = periodEnd.clone().day(weekday - 7);
        } else {
          periodStart = periodStart.clone().startOf('month').day(weekNum * 7 + weekday);
        }
      } else {
        periodEnd = periodStart.clone().add(6, 'days');
      }
    } else if (challenge.schedule_type === 'specific_date') {
      periodStart = periodStart.date(challenge.schedule_days[0]);
      periodEnd = periodStart.clone();
    } else {
      periodEnd = periodStart.clone().add(1, periodLength).subtract(1, 'day');
    }

    return `${periodStart.format('MMM D')} - ${periodEnd.format('MMM D')}`;
  };


  const getScheduleDescription = (challenge) => {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const weekOptions = ['First', 'Second', 'Third', 'Fourth', 'Last'];
    const durationType = challenge.duration_type || 'weekly';

    if (challenge.schedule_type === 'specific_weekday') {
      const weekDescription = durationType === 'monthly' 
        ? `${weekOptions[challenge.schedule_week === -1 ? 4 : challenge.schedule_week]} ` 
        : '';
      const days = (challenge.schedule_days || []).map(day => weekdays[day]).join(', ');
      return `Check-ins on ${weekDescription}${days}`;
    } else if (challenge.schedule_type === 'specific_date') {
      return `Monthly check-ins on date(s): ${challenge.schedule_days?.join(', ') || 'scheduled dates'}`;
    }
    return `Regular ${durationType} check-ins`;
  };


  const getProgressPercentage = (challenge) => {
    const progress = challenge.period_progress || challenge.weekly_progress || [];
    if (progress.length === 0) return 0;
    const completedPeriods = progress.filter(period => period === 'completed').length;
    return (completedPeriods / progress.length) * 100;
  };

  const handleReflectionChange = (challengeId, periodIndex, text) => {
    setReflections(prev => ({
      ...prev,
      [challengeId]: {
        ...prev[challengeId],
        [periodIndex]: text
      }
    }));
  };

  const toggleExpandChallenge = (challengeId) => {
    setExpandedChallenge(expandedChallenge === challengeId ? null : challengeId);
  };

  const toggleShowAllReflections = (challengeId, periodIndex) => {
    setShowAllReflections(prev => ({
      ...prev,
      [challengeId]: {
        ...(prev[challengeId] || {}),
        [periodIndex]: !(prev[challengeId]?.[periodIndex])
      }
    }));
  };


  const handlePeriodUpdate = async (challengeId, periodIndex, reflection, completed, isAdditionalReflection = false) => {
    if (loading[challengeId]) return;

    try {
      setLoading(prev => ({ ...prev, [challengeId]: true }));
      
      const reflectionText = reflection || reflections[challengeId]?.[periodIndex] || '';
      if (!reflectionText.trim()) {
        alert("Please provide a reflection before checking in.");
        return;
      }

      const challenge = challenges.find(c => c.id === challengeId);
      if (!challenge) return;

      // Add check-in validation
      if (!isAdditionalReflection && !isValidCheckInDate(challenge)) {
        alert("Check-in is not available at this time. Please check the schedule for valid check-in dates.");
        return;
      }

      const isGroupChallenge = Boolean(challenge.group_id);

      const payload = {
        user_id: userId,
        challenge_id: parseInt(challengeId, 10),
        period_index: parseInt(periodIndex, 10),
        reflection: reflectionText.trim(),
        completed: Boolean(completed),
        timestamp: moment().format(),
        is_additional: Boolean(isAdditionalReflection),
        // Add scheduling information
        schedule_type: challenge.schedule_type,
        duration_type: challenge.duration_type,
        schedule_days: challenge.schedule_days,
        schedule_week: challenge.schedule_week
      };

      let response;
      if (isGroupChallenge) {
        const checkInUrl = API_ENDPOINTS.GROUP_CHALLENGE_CHECKIN
          .replace(':groupId', challenge.group_id)
          .replace(':challengeId', challengeId);
        response = await axios.post(checkInUrl, payload);
      } else {
        response = await axios.post(`${API_ENDPOINTS.CHALLENGES}/${userId}`, payload);
      }

      if (response.data) {
        setWeeklyReflections(prev => ({
          ...prev,
          [challengeId]: {
            ...(prev[challengeId] || {}),
            [periodIndex]: [
              ...(prev[challengeId]?.[periodIndex] || []),
              {
                text: reflectionText,
                timestamp: payload.timestamp,
                is_additional: payload.is_additional
              }
            ]
          }
        }));

        setReflections(prev => ({
          ...prev,
          [challengeId]: {
            ...prev[challengeId],
            [periodIndex]: ''
          }
        }));

        const updatedChallenges = challenges.map(c => {
          if (c.id === challengeId) {
            return {
              ...c,
              period_progress: response.data.period_progress,
              period_reflections: {
                ...c.period_reflections,
                [periodIndex]: response.data.period_reflections
              },
              // Update scheduling information
              current_period: response.data.current_period,
              next_check_in: response.data.next_check_in
            };
          }
          return c;
        });

        await onChallengeUpdate(updatedChallenges);

        if (shareAsComment[`${challengeId}-${periodIndex}`]) {
          try {
            await axios.post(
              API_ENDPOINTS.CREATE_CHALLENGE_COMMENT
                .replace(':groupId', challenge.group_id)
                .replace(':challengeId', challengeId),
              {
                user_id: userId,
                week_index: periodIndex,
                content: reflectionText,
                // Include scheduling context in comment
                check_in_type: isAdditionalReflection ? 'additional' : 'scheduled',
                period_type: challenge.duration_type,
                check_in_date: moment().format('YYYY-MM-DD')
              }
            );
            
            setShareAsComment(prev => ({
              ...prev,
              [`${challengeId}-${periodIndex}`]: false
            }));
            alert('Your reflection has been shared to the group comments!');
          } catch (error) {
            console.error('Error sharing reflection as comment:', error);
            alert('Your check-in was saved but there was an error sharing it to the group comments.');
          }
        }

        if (response.data.challengeCompleted) {
          alert('Challenge completed! Please review and select your next challenge.');
          onInitiateNextChallenges?.();
        } else {
          // Include next check-in date in success message if available
          const successMessage = response.data.next_check_in 
            ? `Check-in successful! Next check-in available on ${moment(response.data.next_check_in).format('MMM D, YYYY')}`
            : 'Check-in successful!';
          alert(successMessage);
        }
      }
    } catch (error) {
      console.error('Error updating challenge:', error);
      console.error('Error details:', error.response?.data);
      
      // Enhanced error handling for scheduling issues
      const errorMessage = error.response?.data?.error === 'invalid_check_in_date'
        ? 'This check-in is not available at the scheduled time. Please check the challenge schedule.'
        : error.response?.data?.error || error.message || 'An unexpected error occurred';
      
      alert(`Failed to check in: ${errorMessage}`);
    } finally {
      setLoading(prev => ({ ...prev, [challengeId]: false }));
    }
  };

  const renderWeeklyReflections = (challenge, index) => {
    const reflections = weeklyReflections[challenge.id]?.[index] || [];
    const showAll = showAllReflections[challenge.id]?.[index];
    
    return reflections.length > 0 ? (
      <div className="weekly-reflections">
        <h6 className="reflections-header">
          <FaList /> Reflections this {challenge.duration_type}
          <button 
            onClick={() => toggleShowAllReflections(challenge.id, index)}
            className="toggle-reflections-button"
          >
            {showAll ? 'Show Less' : `Show All (${reflections.length})`}
          </button>
        </h6>
        {(showAll ? reflections : reflections.slice(-1)).map((entry, idx) => (
          <div key={idx} className="reflection-entry">
            <small className="reflection-date">
              {moment(entry.timestamp).format('MMM D, YYYY h:mm A')}
            </small>
            <p className="reflection-text">{entry.text}</p>
            {entry.is_additional && (
              <span className="additional-reflection-badge">Additional Reflection</span>
            )}
          </div>
        ))}
      </div>
    ) : null;
  };

  const processAndGroupChallenges = () => {
    try {
      const groupChallenges = challenges.filter(c => c.group_id);
      const enrichedGroupChallenges = groupChallenges.map(challenge => ({
        ...challenge,
        isGroupChallenge: true,
        category: 'Group Challenges',
        title: challenge.title || challenge.description,
        group_name: groupNames[challenge.group_id] || 'Group Challenge'
      }));

      const regularChallenges = challenges.filter(c => !c.group_id);
      const filteredRegularChallenges = regularChallenges.filter(challenge => 
        challenge.category !== 'Custom' || !personalGoals.some(goal => goal.id === challenge.id)
      );

      const allChallenges = [...enrichedGroupChallenges, ...filteredRegularChallenges, ...personalGoals];
      return allChallenges.reduce((acc, challenge) => {
        const category = challenge.isGroupChallenge ? 'Group Challenges' : challenge.category;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(challenge);
        return acc;
      }, {});
    } catch (error) {
      console.error('Error processing challenges:', error);
      return { 'General Challenges': challenges };
    }
  };


  const renderChallenge = (challenge) => {
    const isCustomGoal = challenge.goalType === 'personal-goal';
    const isGroupChallenge = Boolean(challenge.group_id) || challenge.isGroupChallenge;
    const totalPeriods = challenge.duration_periods || 4;
    const periodType = challenge.duration_type === 'weekly' ? 'Week' : 'Month';
    

    let prompts = [];
    const periodLabel = challenge.duration_type === 'weekly' ? 'week' : 'month';
    if (challenge.weekly_prompts) {
      prompts = challenge.weekly_prompts;
    } else if (challenge.period_prompts) {
      prompts = challenge.period_prompts;
    } else if (challenge.id === 'sample-challenge') {
      prompts = challenge.period_prompts;
    } else {
      prompts = Array(totalPeriods).fill(`Reflect on your progress this ${periodLabel}`);
    }

    const progress = challenge.period_progress || challenge.weekly_progress || Array(totalPeriods).fill('not_started');
    const isTitleLong = (challenge.title || challenge.description || '').length > 17;

    return (
      <div key={challenge.id} className="challenge-card">
        <div className="challenge-header">
          {isGroupChallenge && (
            <div className="group-badge">
              <FaUsers /> {challenge.group_name || groupNames[challenge.group_id] || 'Group Challenge'}
            </div>
          )}
          <h4 className={isTitleLong ? 'long-title' : ''}>
            {challenge.title || challenge.description}
          </h4>
          <span className="challenge-type">
            {isGroupChallenge ? 'Group Challenge' : `${challenge.category} ${isCustomGoal ? 'Goal' : 'Challenge'}`}
          </span>
        </div>

        <div className="challenge-progress">
          <div className="progress-bar" style={{ width: `${getProgressPercentage(challenge)}%` }}></div>
        </div>
        <p className="progress-text">Progress: {getProgressPercentage(challenge).toFixed(0)}%</p>

        <div className="schedule-info">
          <FaCalendar /> {getScheduleDescription(challenge)}
          {challenge.start_date && (
            <div className="next-checkin">
              Next check-in: {getNextCheckInDate(challenge).format('MMM D, YYYY')}
            </div>
          )}
        </div>

        <button onClick={() => toggleExpandChallenge(challenge.id)} className="expand-button">
          {expandedChallenge === challenge.id ? 'Hide Details' : 'Show Details'}
        </button>

        {expandedChallenge === challenge.id && (
          <div className="challenge-details">
            {Array.from({ length: totalPeriods }).map((_, index) => {
              const prompt = prompts[index] || `Reflect on your progress this ${periodType}`;
              const isCompleted = progress[index] === 'completed';

              return (
                <div key={`${challenge.id}-period-${index}`} className="period-prompt">
                  <h5>
                    {periodType} {index + 1}
                    {challenge.start_date && ` (${getPeriodDates(challenge, index)})`}
                  </h5>
                  <p>{prompt}</p>

                  {renderWeeklyReflections(challenge, index)}

                  {(isCompleted || index <= progress.findIndex(p => p !== 'completed')) && (
                    <div className="new-reflection-section">
                      <textarea 
                        placeholder="Share your reflection (2-4 sentences)..."
                        value={reflections[challenge.id]?.[index] || ''}
                        onChange={(e) => handleReflectionChange(challenge.id, index, e.target.value)}
                        className="reflection-input"
                      />

                      {isGroupChallenge && (
                        <div className="share-options">
                          <label className="share-checkbox-label">
                            <input
                              type="checkbox"
                              checked={shareAsComment[`${challenge.id}-${index}`] || false}
                              onChange={(e) => setShareAsComment(prev => ({
                                ...prev,
                                [`${challenge.id}-${index}`]: e.target.checked
                              }))}
                              className="share-checkbox"
                            />
                            Share this reflection in group comments for {periodType} {index + 1}
                          </label>
                        </div>
                      )}

                      {challenge.id === 'sample-challenge' ? (
                        <button 
                          className="check-in-button disabled"
                          disabled
                          title="Register to check-in on goals"
                        >
                          <FaLock /> Complete assessment to check-in
                        </button>
                      ) : (
                        <button 
                          onClick={async () => {
                            if (isGroupChallenge) {
                              await handlePeriodUpdate(
                                challenge.id,
                                index,
                                reflections[challenge.id]?.[index] || '',
                                !isCompleted,
                                isCompleted
                              );
                            } else if (isCustomGoal) {
                              onPersonalGoalUpdate(
                                challenge.id,
                                index,
                                reflections[challenge.id]?.[index] || '',
                                !isCompleted
                              );
                            } else {
                              handlePeriodUpdate(
                                challenge.id,
                                index,
                                reflections[challenge.id]?.[index] || '',
                                !isCompleted
                              );
                            }
                          }}
                          className={`check-in-button ${isCompleted ? 'additional-reflection' : ''}`}
                          disabled={loading[challenge.id]}
                        >
                          {loading[challenge.id] ? (
                            <>
                              <FaSpinner className="fa-spin" /> Loading...
                            </>
                          ) : (
                            <>
                              {isCompleted ? (
                                <>
                                  <FaPlusCircle /> Add Another Reflection
                                </>
                              ) : (
                                <>
                                  <FaCalendarCheck /> Check-in
                                </>
                              )}
                            </>
                          )}
                        </button>
                      )}

                      {isGroupChallenge && (
                        <div className="group-challenge-info">
                          <small>
                            <FaUsers /> Group challenge for {groupNames[challenge.group_id]}
                          </small>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const groupedChallenges = processAndGroupChallenges();

  return (
    <div className="challenges-dashboard">
      <div className="dashboard-header">
        <div className="dashboard-title">
          <FaChartLine className="dashboard-icon" />
          <h2>Your Faith Journey Activities</h2>
        </div>
        {(!challenges?.length && !personalGoals?.length) && (
          <div className="empty-challenges-message">
            <p>Select your first faith activity below to begin your journey.</p>
            <div className="empty-challenges-divider"></div>
          </div>
        )}
      </div>

      {(challenges?.length > 0 || personalGoals?.length > 0) && (
        <div className="categories-container">
          {groupedChallenges['Group Challenges']?.length > 0 && (
            <div key="Group Challenges" className="category-section">
              <h3><FaUsers /> Group Activities</h3>
              <div className="challenges-list">
                {groupedChallenges['Group Challenges'].map(challenge => renderChallenge(challenge))}
              </div>
            </div>
          )}
          
          {Object.entries(groupedChallenges)
            .filter(([category]) => category !== 'Group Challenges')
            .map(([category, categoryChallenges]) => (
              <div key={category} className="category-section">
                <h3>
                  <FaTasks /> {category}
                  {category !== 'General Challenges' && (
                    <p className="category-level">Level: {userLevels[category] || 'Custom Goal'}</p>
                  )}
                </h3>
                <div className="challenges-list">
                  {categoryChallenges.map(challenge => renderChallenge(challenge))}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ChallengesDashboard;


