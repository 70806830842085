import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import './GroupInvite.css';

const GroupInvite = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const userId = useSelector(state => state.user.userId);

  useEffect(() => {
    if (isAuthenticated) {
      // If user is logged in, go directly to group page
      navigate(`/group/${groupId}`);
    } else {
      // Store group info and redirect to signup
      localStorage.setItem('pendingGroupInvite', JSON.stringify({
        groupId,
        timestamp: Date.now()
      }));
      navigate('/signup', { 
        state: { 
          fromInvite: true,
          groupId 
        }
      });
    }
  }, [isAuthenticated, userId, groupId, navigate]);

  return (
    <div className="group-invite-container">
      <Header />
      <div className="group-invite-content">
        <Navbar />
        <div className="group-invite-main">
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <p>Processing invitation...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupInvite;