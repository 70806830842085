import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import "./SignUp.css";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Navbar/Navbar";
import SignUpForm from "../../components/SignUpForm/SignUpForm";

const SignUp = () => {
  const navigate = useNavigate();
  const { referralId } = useParams();
  const location = useLocation();
  const fromInvite = location.state?.fromInvite;
  const queryParams = new URLSearchParams(location.search);
  const groupId = queryParams.get('groupId');

  const handleSignUpSuccess = async (userId, invitedGroupId) => {
    // First attempt: Try to join group from URL parameter
    if (invitedGroupId) {
      try {
        // Attempt to join the group using the JOIN_GROUP endpoint
        const joinEndpoint = API_ENDPOINTS.JOIN_GROUP.replace(':groupId', invitedGroupId);
        await axios.post(joinEndpoint, { user_id: userId });

        // If successful, navigate to the group page
        navigate(`/group/${invitedGroupId}`, {
          state: {
            newUser: true,
            userId: userId,
            fromInvite: true
          }
        });
        return;
      } catch (error) {
        console.error('Error joining group:', error);
        // If joining fails, we'll fall through to the default navigation
      }
    }

    // Second attempt: Check localStorage (keeping your existing logic)
    const pendingInvite = localStorage.getItem('pendingGroupInvite');
    if (pendingInvite) {
      const { groupId, timestamp } = JSON.parse(pendingInvite);
      const oneHourAgo = Date.now() - (60 * 60 * 1000);
      
      if (timestamp > oneHourAgo) {
        try {
          // Try to join using the stored group ID
          await axios.post(
            API_ENDPOINTS.JOIN_GROUP.replace(':groupId', groupId),
            { user_id: userId }
          );
          
          // Clear the stored invite
          localStorage.removeItem('pendingGroupInvite');
          
          // Navigate to the group page
          navigate(`/group/${groupId}`, { 
            state: { 
              newUser: true, 
              userId: userId,
              fromInvite: true 
            }
          });
          return;
        } catch (error) {
          console.error('Error joining group from localStorage:', error);
        }
      } else {
        // Clear expired invite
        localStorage.removeItem('pendingGroupInvite');
      }
    }
    
    // Default navigation if no group joins were successful
    navigate("/chat", { state: { newUser: true, userId: userId } });
  };

  return (
    <div className="signup-container">
      <Header />
      <div className="signup-content">
        <Navbar />
        <div className="signup-main">
          <div className="signup-scroll-container">
            <h1 className="signup-title">Register Your Account</h1>
            <div className="plan-info">
              <h2>Welcome to CrossValidation.ai</h2>
              {(fromInvite || groupId) && (
                <div className="invite-info">
                  <p className="invite-message">
                    You've been invited to join a faith group! 
                    Complete your registration to join the group.
                  </p>
                </div>
              )}
              <p>
                Register for a free account below (by doing so you agree to our{' '}
                <a 
                  href="https://docs.google.com/document/d/1lbq3nwlzCUtoYLNQM94qaiYvVOD1EXS_mNYbp3LQN0o/edit?usp=sharing" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  terms and conditions
                </a>).
              </p>
              {referralId && (
                <p className="referral-info">
                  You've been referred by a friend! You'll both receive benefits when you sign up.
                </p>
              )}
            </div>
            <SignUpForm 
              onSuccess={handleSignUpSuccess} 
              referralId={referralId}
              groupId={groupId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;