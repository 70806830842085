import React, { useState } from 'react';
import { 
  FaRobot, 
  FaKey, 
  FaCopy, 
  FaCheck, 
  FaCode, 
  FaPlayCircle,
  FaCheckCircle,
  FaCog
} from 'react-icons/fa';
import './OpenAISetup.css';

const OpenAISetup = ({ onComplete, isCompleted }) => {
  const [activeSection, setActiveSection] = useState('api-setup');
  const [copiedCommand, setCopiedCommand] = useState('');
  const [testResponse, setTestResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [verificationStatus, setVerificationStatus] = useState({
    'api-key': false,
    'env-setup': false,
    'package-install': false,
    'test-prompt': false
  });

  const copyToClipboard = (command, id) => {
    navigator.clipboard.writeText(command);
    setCopiedCommand(id);
    setTimeout(() => setCopiedCommand(''), 2000);
  };

  const handleVerification = (type) => {
    setVerificationStatus(prev => ({
      ...prev,
      [type]: true
    }));
    checkSectionCompletion();
  };

  const checkSectionCompletion = () => {
    const currentSection = sections[activeSection];
    const allStepsCompleted = currentSection.steps.every(
      step => verificationStatus[step.id]
    );
    if (allStepsCompleted) {
      onComplete(activeSection);
    }
  };

  const mockTestAPI = async () => {
    setIsLoading(true);
    try {
      // Simulated API test
      await new Promise(resolve => setTimeout(resolve, 2000));
      setTestResponse({
        success: true,
        message: 'API connection successful! Your setup is working correctly.'
      });
      handleVerification('test-prompt');
    } catch (error) {
      setTestResponse({
        success: false,
        message: 'API connection failed. Please check your configuration.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const sections = {
    'api-setup': {
      title: 'OpenAI API Setup',
      icon: <FaKey />,
      steps: [
        {
          id: 'api-key',
          title: 'Get API Key',
          content: (
            <div className="api-key-setup">
              <ol className="setup-steps">
                <li>Go to <a href="https://platform.openai.com/signup" target="_blank" rel="noopener noreferrer">OpenAI Platform</a></li>
                <li>Create an account or sign in</li>
                <li>Navigate to API keys section</li>
                <li>Create a new secret key</li>
              </ol>
              <div className="api-key-input">
                <input
                  type="password"
                  placeholder="Paste your API key here"
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                />
                <button 
                  onClick={() => handleVerification('api-key')}
                  disabled={!apiKey}
                >
                  Verify Key
                </button>
              </div>
            </div>
          )
        },
        {
          id: 'env-setup',
          title: 'Set Environment Variable',
          content: (
            <div className="command-block">
              <div className="command-header">
                <span>Set OpenAI API Key as environment variable</span>
                <button
                  className="copy-button"
                  onClick={() => copyToClipboard(`export OPENAI_API_KEY='${apiKey}'`, 'env-setup')}
                >
                  {copiedCommand === 'env-setup' ? <FaCheck /> : <FaCopy />}
                </button>
              </div>
              <pre>
                <code>{`export OPENAI_API_KEY='${apiKey || 'your-api-key'}'`}</code>
              </pre>
            </div>
          )
        }
      ]
    },
    'prompt-testing': {
      title: 'Test Prompt Setup',
      icon: <FaCode />,
      steps: [
        {
          id: 'package-install',
          title: 'Install Required Package',
          content: (
            <div className="command-block">
              <div className="command-header">
                <span>Install OpenAI package</span>
                <button
                  className="copy-button"
                  onClick={() => copyToClipboard('pip install openai', 'pip-install')}
                >
                  {copiedCommand === 'pip-install' ? <FaCheck /> : <FaCopy />}
                </button>
              </div>
              <pre>
                <code>pip install openai</code>
              </pre>
            </div>
          )
        },
        {
          id: 'test-prompt',
          title: 'Test API Connection',
          content: (
            <div className="test-prompt-section">
              <div className="code-preview">
                <pre>
                  <code>{`import openai

# Test API connection
response = openai.ChatCompletion.create(
    model="gpt-4",
    messages=[
        {"role": "system", "content": "You are a Catholic spiritual advisor."},
        {"role": "user", "content": "Provide a short prayer for guidance."}
    ]
)

print(response.choices[0].message['content'])`}</code>
                </pre>
              </div>
              <button 
                className="test-api-button"
                onClick={mockTestAPI}
                disabled={isLoading}
              >
                {isLoading ? <FaCog className="spinning" /> : <FaPlayCircle />}
                {isLoading ? 'Testing...' : 'Test API Connection'}
              </button>
              {testResponse && (
                <div className={`test-response ${testResponse.success ? 'success' : 'error'}`}>
                  {testResponse.message}
                </div>
              )}
            </div>
          )
        }
      ]
    }
  };

  return (
    <div className="openai-setup">
      <div className="setup-sections">
        {Object.entries(sections).map(([id, section]) => (
          <button
            key={id}
            className={`section-tab ${activeSection === id ? 'active' : ''} ${
              isCompleted(id) ? 'completed' : ''
            }`}
            onClick={() => setActiveSection(id)}
          >
            {section.icon}
            <span>{section.title}</span>
            {isCompleted(id) && <FaCheckCircle className="completed-icon" />}
          </button>
        ))}
      </div>

      <div className="section-content">
        <h3 className="section-title">
          {sections[activeSection].icon}
          {sections[activeSection].title}
        </h3>
        
        <div className="steps-container">
          {sections[activeSection].steps.map((step) => (
            <div key={step.id} className="setup-step">
              <h4 className="step-title">
                {step.title}
                {verificationStatus[step.id] && (
                  <FaCheckCircle className="step-complete-icon" />
                )}
              </h4>
              {step.content}
            </div>
          ))}
        </div>
      </div>

      <div className="setup-progress">
        <div className="progress-bar">
          <div 
            className="progress-fill"
            style={{
              width: `${
                Object.values(verificationStatus).filter(Boolean).length * 25
              }%`
            }}
          />
        </div>
        <span className="progress-text">
          {Object.values(verificationStatus).filter(Boolean).length} of 4 steps completed
        </span>
      </div>
    </div>
  );
};

export default OpenAISetup;